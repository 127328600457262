<template>
  <v-autocomplete v-model="siteId" label="Choose a site" item-title="siteName" item-value="siteId" :loading="sitesLoading" :items="sites" clearable />
</template>

<script setup lang="ts">
import { ListSites200ResponseDataItemsInner } from '@tableyeti/merchant-service/api.ts';
import { onMounted, ref } from 'vue';
import { useYetipayApi } from '@/composables/useYetipayApi';
const { api, currentMerchantId } = useYetipayApi();
const siteId = defineModel<string | undefined>();
const sites = ref<ListSites200ResponseDataItemsInner[]>([]);
const sitesLoading = ref(false);

onMounted(async () => {
  sitesLoading.value = true;
  sites.value = await api.listSites(currentMerchantId).then((response) => response.data.data.items);
  if (sites.value.length === 1) {
    siteId.value = sites.value[0].siteId;
  }
  sitesLoading.value = false;
});
</script>
