<template>
  <div align="center">
    <v-sheet class="d-flex flex-column" align="left" max-width="960" color="background">
      <DeviceOrderImpossible
        v-if="!canOrderDevices"
        :missing-payment-method="billingStore.showNoPaymentMethodsWarning"
        :missing-deal="!billingStore.hasRates"
        :deal-not-signed="!billingStore.hasRatesSigned"
        :deal-url="billingStore.signRatesUrl"
        :is-staff="authorizationStore.isStaff"
        @associate-deal-reference="linkDealReference"
      />

      <v-window v-else :model-value="currentStep" :touch="false" class="my-5">
        <v-window-item :value="OrderSteps.SITE_SELECTION">
          <v-form>
            <p>Which site are you ordering devices for?</p>
            <v-autocomplete
              v-model="form.stepOne.siteId"
              :items="sites"
              item-title="siteName"
              item-value="siteId"
              label="Select site"
              :loading="sitesLoading"
              clearable
              :error-messages="validationErrors(v$.stepOne.$errors)"
              required
            />
          </v-form>
          <v-alert v-if="thereWasAProblem" color="red lighten-2" dark>Issue occurred when fetching site data. Please refresh the page.</v-alert>
          <div class="text-center">
            <v-btn :loading="siteDetailsLoading" :disabled="v$.stepOne.$invalid || siteDetailsLoading" @click="currentStep = OrderSteps.BASKET_DEVICES">
              Order devices
            </v-btn>
          </div>
        </v-window-item>

        <v-window-item :value="OrderSteps.UPDATE_DELIVERY_ADDRESS">
          <div class="d-flex">
            <p class="ms-auto"><span class="text-decoration-underline">Order for site:</span> {{ form.stepSix.name }}</p>
          </div>
          <p class="text-uppercase">
            <span class="font-weight-bold text-deep-purple-darken-4">Change </span>
            <span class="text-pink">delivery address</span>
          </p>
          <v-alert variant="outlined" type="warning" class="text-body-2 mb-3">
            We do not offer custom delivery windows. Please enter a delivery address where parcels can be delivered between 7am and 1pm, Monday - Saturday.
          </v-alert>
          <br />
          <v-form>
            <p>Enter the address you'd like your devices to be delivered to.</p>
            <v-text-field
              v-model.trim="form.stepSix.address.line1"
              label="Address line 1"
              :error-messages="validationErrors(v$.stepSix.address.line1.$errors)"
              required
            />
            <v-text-field v-model.trim="form.stepSix.address.line2" label="Address line 2" />
            <v-text-field v-model.trim="form.stepSix.address.city" label="City" :error-messages="validationErrors(v$.stepSix.address.city.$errors)" required />
            <v-text-field
              v-model.trim="form.stepSix.address.postalCode"
              label="postcode"
              :error-messages="validationErrors(v$.stepSix.address.postalCode.$errors)"
              required
            />
            <v-autocomplete
              v-model.trim="form.stepSix.address.country"
              :items="tradedCountries"
              label="Address country (required)"
              item-title="name"
              item-value="code"
              :error-messages="validationErrors(v$.stepSix.address.country.$errors)"
              :disabled="true"
              required
            />
            <v-text-field v-model.trim="form.stepSix.deliveryNotes" label="Additional delivery notes" />
          </v-form>
          <div class="d-flex">
            <v-btn class="ms-auto" :disabled="v$.stepSix.$invalid" @click="currentStep = OrderSteps.CONFIRMATION">Confirm</v-btn>
          </div>
        </v-window-item>

        <v-window-item :value="OrderSteps.BASKET_DEVICES">
          <div class="d-flex">
            <p class="ms-auto"><span class="text-decoration-underline">Order for site:</span> {{ form.stepSix.name }}</p>
          </div>
          <p class="text-uppercase">
            <span class="font-weight-bold text-deep-purple-darken-4">Order step 1 of 3 </span>
            <span class="text-pink">your order</span>
          </p>
          <div class="d-flex" :class="smAndDown ? 'flex-column' : ''">
            <div>
              <p class="text-uppercase font-weight-medium text-deep-purple-darken-4 ms-5">devices</p>
              <div class="d-flex flex-wrap">
                <BasketItem v-model:count="form.stepTwo.s1f2" title="Yeti Peak 4G" class="ma-5" min-width="200" max-width="280" :img="imgDevicePeak">
                  <template #subtitle>
                    <p class="font-italic font-weight-light">
                      With printer. Rented monthly from yetipay. See <a href="http://yetipay.me/terms" target="_blank">terms</a>.
                    </p>
                  </template>
                </BasketItem>
                <BasketItem v-model:count="form.stepTwo.s1e2" title="Yeti Summit 4G" class="ma-5" min-width="200" max-width="280" :img="imgDeviceSummit">
                  <template #subtitle>
                    <p class="font-italic font-weight-light">
                      No printer. Rented monthly from yetipay. See <a href="http://yetipay.me/terms" target="_blank">terms</a>.
                    </p>
                  </template>
                </BasketItem>
              </div>
              <p class="text-uppercase font-weight-medium text-deep-purple-darken-4 ms-5">docks</p>
              <div class="d-flex flex-wrap">
                <BasketItem v-model:count="form.stepTwo.s1f2Dock" title="Yeti Peak 4G Dock" class="ma-5" min-width="200" max-width="280">
                  <template #subtitle>
                    <p class="font-italic font-weight-light">
                      Docking station for Yeti Peak 4G (with printer). Docks are purchased outright and yours to keep. See
                      <a href="http://yetipay.me/terms" target="_blank">terms</a>.
                    </p>
                    <h1 class="text-secondary text-font-raleway">£9.95</h1>
                  </template>
                </BasketItem>
              </div>
            </div>
            <v-sheet color="background" width="250">
              <p class="text-uppercase font-weight-medium text-deep-purple-darken-4">Order summary</p>
              <p v-if="form.stepTwo.s1f2 > 0" class="text-body-2 ms-3">{{ form.stepTwo.s1f2 }} x Yeti Peak 4G (with printer)</p>
              <p v-if="form.stepTwo.s1e2 > 0" class="text-body-2 ms-3">{{ form.stepTwo.s1e2 }} x Yeti Summit 4G (without printer)</p>
              <p v-if="form.stepTwo.s1f2Dock > 0" class="text-body-2 ms-3">{{ form.stepTwo.s1f2Dock }} x Yeti Peak 4G dock</p>
            </v-sheet>
          </div>
          <div class="d-flex mt-5">
            <v-btn-nd @click="currentStep = OrderSteps.SITE_SELECTION">Back</v-btn-nd>
            <v-btn class="ms-auto" :disabled="v$.stepTwo.$invalid" @click="currentStep = OrderSteps.POINT_OF_CONTACT">Continue</v-btn>
          </div>
        </v-window-item>

        <v-window-item :value="OrderSteps.POINT_OF_CONTACT">
          <div class="d-flex">
            <p class="ms-auto"><span class="text-decoration-underline">Order for site:</span> {{ form.stepSix.name }}</p>
          </div>
          <p class="text-uppercase">
            <span class="font-weight-bold text-deep-purple-darken-4">Order step 2 of 3 </span>
            <span class="text-pink">point of contact</span>
          </p>
          <br />
          <v-form>
            <p>Who can we contact about the order? We'll also send shipping notifications to this person.</p>
            <v-text-field
              v-model.trim="form.stepThree.name"
              label="Point of contact name"
              :error-messages="validationErrors(v$.stepThree.name.$errors)"
              required
            />
            <PhoneNumber v-model="form.stepThree.phoneNumber" />
            <v-text-field
              v-model="form.stepThree.email"
              label="Point of contact email"
              :error-messages="validationErrors(v$.stepThree.email.$errors)"
              required
            />
            <v-text-field
              v-model.trim="form.stepThree.role"
              label="Point of contact role"
              :error-messages="validationErrors(v$.stepThree.role.$errors)"
              required
            />
          </v-form>
          <div class="d-flex">
            <v-btn-nd @click="currentStep = OrderSteps.BASKET_DEVICES">Back</v-btn-nd>
            <v-btn class="ms-auto" :disabled="v$.stepThree.$invalid" @click="currentStep = OrderSteps.CONFIRMATION">Continue</v-btn>
          </div>
        </v-window-item>

        <v-window-item :value="OrderSteps.CONFIRMATION">
          <div class="d-flex">
            <p class="ms-auto"><span class="text-decoration-underline">Order for site:</span> {{ form.stepSix.name }}</p>
          </div>
          <p class="text-uppercase">
            <span class="font-weight-bold text-deep-purple-darken-4">Order step 3 of 3 </span>
            <span class="text-pink">confirm delivery details</span>
          </p>

          <v-form class="pa-5">
            <v-alert variant="outlined" type="warning" class="text-body-2 mb-3">
              Please ensure that you are able to receive parcels between 7am and 1pm Monday to Saturday at your selected delivery address, or update your
              delivery address.
            </v-alert>

            <p class="text-uppercase font-weight-medium text-deep-purple-darken-4">
              Your order:
              <i class="material-icons-round" style="position: relative; top: 5px; cursor: pointer" @click="currentStep = OrderSteps.BASKET_DEVICES">edit</i>
            </p>

            <p v-if="form.stepTwo.s1f2 > 0" class="text-body-2 ms-3">{{ form.stepTwo.s1f2 }} x Yeti Peak 4G (with printer)</p>
            <p v-if="form.stepTwo.s1e2 > 0" class="text-body-2 ms-3">{{ form.stepTwo.s1e2 }} x Yeti Summit 4G (without printer)</p>
            <p v-if="form.stepTwo.s1f2Dock > 0" class="text-body-2 ms-3">{{ form.stepTwo.s1f2Dock }} x Yeti Peak 4G dock</p>

            <p class="text-uppercase font-weight-medium text-deep-purple-darken-4">
              Delivery address:
              <i class="material-icons-round" style="position: relative; top: 5px; cursor: pointer" @click="currentStep = OrderSteps.UPDATE_DELIVERY_ADDRESS">
                edit
              </i>
            </p>
            <p class="text-body-2 ms-3">
              {{ form.stepSix.name }}<br />
              {{ form.stepSix.address.line1 }}<br />
              {{ form.stepSix.address.line2 }}<br v-if="form.stepSix.address.line2" />
              {{ form.stepSix.address.postalCode }}<br />
              {{ form.stepSix.address.city }}<br />
              {{ form.stepSix.address.country }}
              <span v-if="form.stepSix.deliveryNotes" class="font-italic"><br />Delivery notes: {{ form.stepSix.deliveryNotes }}</span>
            </p>

            <p class="text-uppercase font-weight-medium text-deep-purple-darken-4">
              Delivery point of contact:
              <i class="material-icons-round" style="position: relative; top: 5px; cursor: pointer" @click="currentStep = OrderSteps.POINT_OF_CONTACT">
                edit
              </i>
            </p>

            <p class="text-body-2 ms-3">
              {{ form.stepThree.name }}<br />
              {{ form.stepThree.phoneNumber }}<br />
              {{ form.stepThree.email }}<br />
              {{ form.stepThree.role }}
            </p>

            <v-checkbox
              v-model="form.stepFour.termsAccepted"
              :error-messages="v$.stepFour.$invalid ? ['You must agree to the terms before placing an order'] : []"
            >
              <template #label>
                I agree to the yetipay.me device rental&nbsp;
                <v-tooltip location="bottom">
                  <template #activator="{ props }">
                    <a target="_blank" href="http://yetipay.me/terms" v-bind="props" @click.stop>terms</a>
                  </template>
                  Opens in new window</v-tooltip
                >.
              </template>
            </v-checkbox>
          </v-form>
          <div class="d-flex">
            <v-btn class="ms-auto" :disabled="v$.$invalid" :loading="orderLoading" @click="placeOrder">Place order</v-btn>
          </div>
        </v-window-item>

        <v-window-item :value="OrderSteps.SUCCESS" class="text-center">
          <div v-if="!thereWasAProblem">
            <p class="font-weight-medium">Order received, thank you!</p>
            <p>Shipping notifications will be sent to your point of contact, {{ form.stepThree.name }}, via text and email.</p>
          </div>
          <div v-else-if="notActiveCustomerOrderError">
            <p class="font-weight-medium">Order failed</p>
            <p>Your account is deactivated. You can't place any new order.</p>
          </div>
          <div v-else>
            <p class="font-weight-medium">Order failed</p>
            <p>
              Something went wrong sending that order.<br /><br />
              Please try again.<br /><br />
              <v-btn width="130px" height="50px" class="text-capitalize" color="primary" :loading="orderLoading" :disabled="orderLoading" @click="tryAgain">
                Try again
              </v-btn>
            </p>
          </div>
        </v-window-item>
      </v-window>
    </v-sheet>
  </div>
</template>

<script setup lang="ts">
import { ListSites200ResponseDataItemsInner } from '@tableyeti/merchant-service/api.ts';
import { ErrorObject, useVuelidate } from '@vuelidate/core';
import { required, email, sameAs, minValue, maxValue } from '@vuelidate/validators';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { AxiosError } from 'axios';
import * as _ from 'lodash-es';
import { inject, computed, onMounted, ref, reactive, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useDisplay } from 'vuetify';
import { NewDeviceRequest } from '@/api/merchant-service/device';
import { Site } from '@/api/merchant-service/site';
import imgDevicePeak from '@/assets/img/yeti-peak-4g.png';
import imgDeviceSummit from '@/assets/img/yeti-summit-4g.png';
import { useYetipayApi } from '@/composables/useYetipayApi';
import { API } from '@/plugins/api';
import { useApplicationStore } from '@/store/application';
import { useAuthorizationStore } from '@/store/authorization';
import { useBillingStore } from '@/store/billing';
import { useMerchantsStore } from '@/store/merchants';
import { tradedCountries } from '@/utils/config';

const { smAndDown } = useDisplay();
const billingStore = useBillingStore();
const applicationStore = useApplicationStore();
const merchantsStore = useMerchantsStore();
const authorizationStore = useAuthorizationStore();
const route = useRoute();
const router = useRouter();

const { api, currentMerchantId } = useYetipayApi();
const legacyApi = inject<API>('api');

enum OrderSteps {
  SITE_SELECTION = 1,
  BASKET_DEVICES = 2,
  POINT_OF_CONTACT = 3,
  CONFIRMATION = 4,
  SUCCESS = 5,
  UPDATE_DELIVERY_ADDRESS = 6,
}

const currentStep = ref(OrderSteps.SITE_SELECTION);
const thereWasAProblem = ref(false);
const thereWasAProblemRetryable = ref(false);
const notActiveCustomerOrderError = ref(false);
const orderLoading = ref(false);
const siteDetailsLoading = ref(false);
const selectedSiteDetails = ref<Site | null>(null);

const canOrderDevices = computed(() => billingStore.hasRates && billingStore.hasRatesSigned && !billingStore.showNoPaymentMethodsWarning);
const linkDealReference = async (dealReference: string) => {
  try {
    await legacyApi?.merchant.associateDeal(merchantsStore.currentMerchant!.merchantId, dealReference);
    applicationStore.notifyUser({ message: 'Company linked to deal', type: 'success' });
    router.go(0);
  } catch (error) {
    console.error(error);
    applicationStore.notifyUser({ message: 'Failed to link company to deal', type: 'error' });
  }
};

const form = reactive({
  stepOne: {
    siteId: '',
  },
  stepTwo: {
    s1e2: 0,
    s1f2: 0,
    s1f2Dock: 0,
  },
  stepThree: {
    name: '',
    phoneNumber: '',
    email: '',
    role: '',
  },
  stepFour: {
    termsAccepted: false,
  },
  stepSix: {
    name: '',
    address: {
      line1: '',
      line2: '',
      postalCode: '',
      city: '',
      country: '',
    },
    deliveryNotes: '',
  },
});
const v$ = useVuelidate(
  {
    stepOne: {
      siteId: { required, $autoDirty: true },
    },
    stepTwo: {
      s1e2: { minValue: minValue(0), maxValue: maxValue(100), $autoDirty: true },
      s1f2: { minValue: minValue(0), maxValue: maxValue(100), $autoDirty: true },
      s1f2Dock: { minValue: minValue(0), maxValue: maxValue(100), $autoDirty: true },
      valid: () => form.stepTwo.s1e2 + form.stepTwo.s1f2 + form.stepTwo.s1f2Dock > 0,
    },
    stepThree: {
      name: { required, $autoDirty: true },
      phoneNumber: { required, valid: (value: string) => parsePhoneNumber(value).valid, $autoDirty: true },
      email: { required, email, $autoDirty: true },
      role: { required, $autoDirty: true },
    },
    stepFour: {
      termsAccepted: { isChecked: sameAs(true), $autoDirty: true },
    },
    stepSix: {
      address: {
        line1: { required, $autoDirty: true },
        line2: { $autoDirty: true },
        postalCode: { required, $autoDirty: true },
        city: { required, $autoDirty: true },
        country: { required, $autoDirty: true },
      },
      deliveryNotes: { $autoDirty: true },
    },
  },
  form,
);

const sitesLoading = ref(false);
const sites = ref<ListSites200ResponseDataItemsInner[]>([]);

onMounted(async () => {
  if (route.query.siteId) {
    form.stepOne.siteId = route.query.siteId as string;
    currentStep.value = OrderSteps.BASKET_DEVICES;
  }
  sitesLoading.value = true;
  try {
    sites.value = await api.listSites(currentMerchantId).then((response) => response.data.data.items);
    if (sites.value.length === 1) {
      form.stepOne.siteId = sites.value[0].siteId;
    }
  } catch (error) {
    console.error(error);
    thereWasAProblem.value = true;
  } finally {
    sitesLoading.value = false;
  }
});

watch(
  () => form.stepOne.siteId,
  async (newSiteId) => {
    if (!newSiteId) return;

    siteDetailsLoading.value = true;
    try {
      const siteDetails: Site = await legacyApi!.site.getSiteDetails(newSiteId);
      selectedSiteDetails.value = siteDetails;
      form.stepSix = {
        name: siteDetails.name,
        address: {
          line1: siteDetails.address.line1,
          line2: siteDetails.address.line2 ?? '',
          city: siteDetails.address.city,
          postalCode: siteDetails.address.postalCode,
          country: siteDetails.address.country,
        },
        deliveryNotes: '',
      };
      history.pushState({}, '', route.path); // Remove the query parameter
    } catch (error) {
      console.error(error);
      thereWasAProblem.value = true;
    } finally {
      siteDetailsLoading.value = false;
    }
  },
);
const deliveryAddressUpdated = computed<boolean>(() => !_.isEqual(selectedSiteDetails.value?.address, form.stepSix.address));
const placeOrder = async () => {
  orderLoading.value = true;
  try {
    const request: NewDeviceRequest = {
      siteId: form.stepOne.siteId,
      basket: {
        s1e2: form.stepTwo.s1e2 > 0 ? form.stepTwo.s1e2 : undefined,
        s1f2: form.stepTwo.s1f2 > 0 ? form.stepTwo.s1f2 : undefined,
        s1f2Dock: form.stepTwo.s1f2Dock > 0 ? form.stepTwo.s1f2Dock : undefined,
      },
      pointOfContact: form.stepThree,
      site: form.stepSix,
      deliveryAddressEdited: deliveryAddressUpdated.value,
      termsAccepted: form.stepFour.termsAccepted,
    };
    await legacyApi!.device.newDeviceRequest(request);
    thereWasAProblem.value = false;
    thereWasAProblemRetryable.value = false;
  } catch (error) {
    console.error(error);
    thereWasAProblem.value = true;
    if (error instanceof AxiosError) {
      if (error.response?.data.error === 'Customer not in status: [active]') {
        notActiveCustomerOrderError.value = true;
      }
      thereWasAProblemRetryable.value = !!error.response && error.response.status >= 500 && error.response.status < 600;
    }
  } finally {
    orderLoading.value = false;
  }
  currentStep.value = OrderSteps.SUCCESS;
};
const tryAgain = () => {
  if (thereWasAProblemRetryable.value) {
    placeOrder();
  } else {
    thereWasAProblem.value = false;
    thereWasAProblemRetryable.value = false;
    currentStep.value = OrderSteps.SITE_SELECTION;
  }
};

const validationErrors = (errors: ErrorObject[]) => errors.map((e) => e.$message.toString());
</script>
