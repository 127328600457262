import { BasecampApi } from '@tableyeti/merchant-service';
import { useAuthenticationStore } from '@/store/authentication';
import { useMerchantsStore } from '@/store/merchants';

let api: BasecampApi | null = null;
let apiNoAuth: BasecampApi | null = null;

export function useYetipayApi() {
  const auth = useAuthenticationStore();
  const merchants = useMerchantsStore();

  if (!api) {
    api = new BasecampApi({
      basePath: import.meta.env.VITE_MERCHANT_SERVICE_BASE_URL,
      accessToken: auth.user.idToken,
      isJsonMime: () => true,
    });
  }

  const currentMerchantId = merchants.currentMerchant!.merchantId;

  return { api, currentMerchantId };
}

export function useYetipayApiNotAuthenticated() {
  if (!apiNoAuth) {
    apiNoAuth = new BasecampApi({
      basePath: import.meta.env.VITE_MERCHANT_SERVICE_BASE_URL,
      apiKey: import.meta.env.VITE_MERCHANT_SERVICE_API_KEY,
      isJsonMime: () => true,
    });
  }

  return { api: apiNoAuth };
}
