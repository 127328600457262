<template>
  <h2 class="mb-6">Integrations</h2>

  <!-- PUSH -->
  <v-card subtitle="Configuration for Till > Yeti integration" elevation="3" class="ma-3 mb-8">
    <template #title>
      <div class="d-flex align-center justify-space-between">
        <div>
          <span class="font-weight-black">Yeti Connect Push</span>
          <v-chip
            v-if="pushMode === Mode.VIEW"
            class="ml-3"
            :color="isPushIntegrated ? 'success' : 'error'"
            :text="isPushIntegrated ? 'Connected' : 'Not connected'"
            :variant="!isPushIntegrated ? 'outlined' : undefined"
          />
        </div>
        <v-btn-nd v-if="pushMode === Mode.VIEW" variant="text" @click="pushMode = Mode.EDIT">
          <v-icon>mdi-pencil</v-icon>
        </v-btn-nd>
        <v-btn-nd v-else variant="text" @click="pushMode = Mode.VIEW">
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn-nd>
      </div>
    </template>

    <v-card-text v-if="pushMode === Mode.EDIT">
      <div v-if="isPushIntegrated" class="d-flex align-center">
        <p class="me-3">
          Integrated with: <kbd>{{ push?.provider }}</kbd>
        </p>
        <v-btn
          :loading="loading"
          color="error"
          @click="
            emit('updateInternalSettings', 'connectPush', undefined);
            pushMode = Mode.VIEW;
          "
          >Remove integration</v-btn
        >
      </div>

      <div v-else class="d-flex align-center">
        <p class="me-3">Integrate with:</p>
        <v-select v-model="pushProvider" hide-details max-width="300" class="me-3" :items="pushIntegrations" />
        <v-btn
          :loading="loading"
          @click="
            emit('updateInternalSettings', 'connectPush', pushToEmit);
            pushMode = Mode.VIEW;
          "
          >Save</v-btn
        >
      </div>
    </v-card-text>

    <v-card-text v-else-if="isPushIntegrated">
      <p>
        Integrated with: <kbd>{{ push?.provider }}</kbd>
      </p>
    </v-card-text>
  </v-card>

  <!-- PULL -->
  <v-card subtitle="Configuration for Device > Yeti integration" elevation="3" class="ma-3">
    <template #title>
      <div class="d-flex align-center justify-space-between">
        <div>
          <span class="font-weight-black">Yeti Connect Pull</span>
          <v-chip
            v-if="pullMode === Mode.VIEW"
            class="ml-3"
            :color="isPullIntegrated ? 'success' : 'error'"
            :text="isPullIntegrated ? 'Connected' : 'Not connected'"
            :variant="!isPullIntegrated ? 'outlined' : undefined"
          />
        </div>
        <v-btn-nd v-if="pullMode === Mode.VIEW" variant="text" @click="pullMode = Mode.EDIT">
          <v-icon>mdi-pencil</v-icon>
        </v-btn-nd>
        <v-btn-nd v-else variant="text" @click="pullMode = Mode.VIEW">
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn-nd>
      </div>
    </template>

    <v-card-text v-if="pullMode === Mode.EDIT">
      <div class="d-flex align-center flex-column w-100">
        <div class="ga-2 d-flex flex-column w-100 mb-6">
          <v-checkbox
            v-model="isPullEnabledInput"
            label="Enable Connect Pull"
            persistent-hint
            hint="Check this box if you want to enable or temporary disable Pull while keeping the existing configuration"
            inset
            color="primary"
            class="mb-4"
            hide-details
          />
          <p>Provider:</p>
          <v-select v-model="pullProviderInput" hide-details density="compact" max-width="300" class="me-3" :items="pullIntegrations" />
        </div>

        <v-textarea
          v-model="pullInternalSettingsInput"
          clearable
          label="Configuration object"
          class="my-2"
          width="100%"
          density="comfortable"
          variant="outlined"
        ></v-textarea>

        <div class="d-flex ga-4">
          <v-btn
            v-if="isPullIntegrated"
            :loading="loading"
            color="error"
            @click="
              emit('updateInternalSettings', 'connectPull', undefined);
              pullMode = Mode.VIEW;
            "
            >Remove integration</v-btn
          >

          <v-btn
            :loading="loading"
            @click="
              emit('updateInternalSettings', 'connectPull', pullToEmit);
              pullMode = Mode.VIEW;
            "
            >Save</v-btn
          >
        </div>
      </div>
    </v-card-text>

    <v-card-text v-else-if="isPullIntegrated" class="d-flex ga-1 flex-column">
      <v-checkbox v-model="isPullEnabledInput" label="Is pull enabled" inset color="primary" disabled hide-details />
      <p class="pl-3">
        Integrated with: <kbd>{{ pull?.provider }}</kbd>
      </p>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import {
  SiteInternalSettingsConnectPush,
  SiteInternalSettingsConnectPushProviderEnum,
  SiteInternalSettingsConnectPull,
  SiteInternalSettingsConnectPullProviderEnum,
} from '@tableyeti/merchant-service';
import { computed, ref } from 'vue';

const { push, pull } = defineProps<{ push?: SiteInternalSettingsConnectPush; pull?: SiteInternalSettingsConnectPull; loading?: boolean }>();
const emit = defineEmits<{ updateInternalSettings: [string, SiteInternalSettingsConnectPush | SiteInternalSettingsConnectPull | undefined] }>();

enum Mode {
  VIEW = 'view',
  EDIT = 'edit',
}

const isPushIntegrated = computed(() => !!push);
const pushMode = ref<Mode>(Mode.VIEW);
const pushIntegrations = Object.values(SiteInternalSettingsConnectPushProviderEnum).map((value) => ({
  title: value,
  value,
  props: { disabled: value !== SiteInternalSettingsConnectPushProviderEnum.PsConnect },
}));
const pushProvider = ref<SiteInternalSettingsConnectPushProviderEnum | undefined>(undefined);
const pushToEmit = computed<SiteInternalSettingsConnectPush | undefined>(() => ({ provider: pushProvider.value! }));

const pullMode = ref<Mode>(Mode.VIEW);
const pullToEmit = computed<SiteInternalSettingsConnectPull | undefined>(() => ({
  enabled: isPullEnabledInput.value,
  provider: pullProviderInput.value!,
  integrationConfig: JSON.parse(pullInternalSettingsInput?.value || '{}'),
}));

const isPullIntegrated = computed(() => !!pull);
const isPullEnabledInput = ref<boolean>(!!pull?.enabled);
const pullProviderInput = ref<SiteInternalSettingsConnectPullProviderEnum | undefined>(pull?.provider);
const pullIntegrations = Object.values(SiteInternalSettingsConnectPullProviderEnum).map((value) => ({
  title: value,
  value,
  props: {},
}));
const pullInternalSettingsInput = ref<string>(JSON.stringify(pull?.integrationConfig, null, 2));
</script>
