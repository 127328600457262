import { DraftMerchant, DraftMerchantStatusEnum, Industry } from '@tableyeti/merchant-service';
import { AxiosError } from 'axios';
import { defineStore } from 'pinia';
import { useYetipayApiNotAuthenticated } from '@/composables/useYetipayApi';

export class OnboardingError extends Error {
  field: string;

  constructor(message: string, field: string) {
    super(message);
    this.name = 'OnboardingError';
    this.field = field;
  }
}

type OnboardingStore = {
  state: {
    shortcode?: string;
    email?: string;
    draftMerchant?: DraftMerchant;
    api: ReturnType<typeof useYetipayApiNotAuthenticated>['api'];
  };
  getters: {
    onboardingStatus(): DraftMerchantStatusEnum | null;
  };
  actions: {
    initialize(): Promise<undefined>;
    setDraftMerchant(draftMerchant: DraftMerchant): void;
    clearDraftMerchant(): void;
    setDraftMerchant(draftMerchant: DraftMerchant): void;
    fetchOrCreateDraftMerchant(shortcode: string, email: string): Promise<DraftMerchant | undefined>;
    updateDraftMerchant(details: Partial<DraftMerchant>): Promise<DraftMerchant>;
    submitDraftMerchant(): Promise<DraftMerchant>;
    getIndustries(): Promise<Array<Industry>>;
  };
};

// TODO: implement as composable
export const useOnboardingStore = defineStore<'onboarding', OnboardingStore['state'], OnboardingStore['getters'], OnboardingStore['actions']>('onboarding', {
  state: () => {
    return {
      shortcode: undefined,
      email: undefined,
      draftMerchant: undefined,
      api: useYetipayApiNotAuthenticated().api,
    };
  },
  getters: {
    onboardingStatus() {
      return this.draftMerchant?.status ?? null;
    },
  },
  actions: {
    async initialize() {
      // TODO: discuss security improvements
      const shortcode = localStorage.getItem('yetipay-onboarding-shortcode');
      const email = localStorage.getItem('yetipay-onboarding-email');
      if (shortcode && email) {
        try {
          const draftMerchant = (await this.api.getDraftMerchant(email, shortcode)).data;
          this.setDraftMerchant(draftMerchant);
        } catch (error) {
          console.error('Error retrieving onboarding session with local storage data', { error });
          this.clearDraftMerchant();
        }
      }
    },
    async getIndustries(): Promise<Array<Industry>> {
      const res = await this.api.getIndustries(this.email, this.shortcode);
      return res.data.data;
    },
    setDraftMerchant(draftMerchant: DraftMerchant) {
      this.draftMerchant = draftMerchant;
      this.shortcode = draftMerchant.dealShortCode;
      this.email = draftMerchant.email;
      localStorage.setItem('yetipay-onboarding-shortcode', draftMerchant.dealShortCode);
      localStorage.setItem('yetipay-onboarding-email', draftMerchant.email);
    },
    clearDraftMerchant() {
      localStorage.removeItem('yetipay-onboarding-shortcode');
      localStorage.removeItem('yetipay-onboarding-email');
      this.shortcode = undefined;
      this.email = undefined;
      this.draftMerchant = undefined;
    },
    async fetchOrCreateDraftMerchant(shortcode: string, email: string) {
      const emailLowerCase = email.toLowerCase();

      try {
        const draftMerchant = (await this.api.getDraftMerchant(emailLowerCase, shortcode)).data;
        this.setDraftMerchant(draftMerchant);
        return draftMerchant;
      } catch (error) {
        if (error instanceof AxiosError && error.status !== 404) {
          console.error('Error fetching draft merchant', error);
          throw new OnboardingError('Unable to start onboarding, please try again or contact support', 'shortcode');
        }
      }

      try {
        const draftMerchant = (await this.api.createDraftMerchant({ dealShortCode: shortcode, email: emailLowerCase })).data;
        this.setDraftMerchant(draftMerchant);
        return draftMerchant;
      } catch (error) {
        if (error instanceof AxiosError) {
          if (error.status === 404) {
            throw new OnboardingError('Shortcode not recognised, please check it and try again', 'shortcode');
          }
          if (error.status === 400 && error.response?.data?.message === 'draft merchant already exists for this dealShortCode') {
            throw new OnboardingError('Onboarding already started for this shortcode, please enter the email used previously', 'email');
          }
        }
        console.error('Error creating draft merchant', error);
        throw new OnboardingError('Unable to start onboarding, please try again or contact support', 'shortcode');
      }
    },
    async updateDraftMerchant(details) {
      if (!this.shortcode || !this.email) {
        throw new Error('No onboarding session found');
      }

      try {
        const draftMerchant = (await this.api.updateDraftMerchantDetails(this.email, this.shortcode, details)).data;
        this.draftMerchant = draftMerchant;
        return draftMerchant;
      } catch (error) {
        console.error('Error updating draft merchant', error);
        throw new OnboardingError('Unable to update onboarding details, please try again or contact support', 'form');
      }
    },
    async submitDraftMerchant() {
      if (!this.shortcode || !this.email) {
        throw new Error('No onboarding session found');
      }

      try {
        const draftMerchant = (await this.api.submitDraftMerchantDetails(this.email, this.shortcode)).data;
        this.draftMerchant = draftMerchant;
        return draftMerchant;
      } catch (error) {
        console.error('Error submitting draft merchant', error);
        throw new OnboardingError('Unable to submit onboarding details, please try again or contact support', 'form');
      }
    },
  },
});
