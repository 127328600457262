<template>
  <v-layout full-height class="bg-purple-4">
    <v-app-bar elevation="0" color="transparent" class="py-3">
      <template #prepend>
        <v-btn v-if="merchantsStore.verifiedAndActiveMerchants.length > 0" icon offset-y style="opacity: 0"></v-btn>
        <v-btn v-if="authStore.user.isAuthenticated" icon offset-y style="opacity: 0"></v-btn>
      </template>

      <v-spacer />
      <RouterLink to="/">
        <v-img src="@/assets/img/yetipay-logo-white.svg" alt="yetipay" width="176px" height="50px" />
      </RouterLink>
      <v-spacer />

      <template #append>
        <v-btn v-if="merchantsStore.verifiedAndActiveMerchants.length > 0" icon offset-y @click="merchantsStore.initialize().then(() => router.push('/'))">
          <v-icon class="on-secondary">mdi-close-circle-outline</v-icon>
        </v-btn>

        <v-menu v-if="authStore.user.isAuthenticated" offset-y>
          <template #activator="{ props }">
            <v-btn icon v-bind="props"><v-icon class="on-secondary">mdi-account-circle</v-icon></v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title><v-icon>mdi-at</v-icon> {{ authStore.user.email }}</v-list-item-title>
            </v-list-item>
            <v-list-item link @click.prevent="logout">
              <v-list-item-title><v-icon>mdi-logout</v-icon> Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-app-bar>

    <v-main class="my-5">
      <router-view v-slot="{ Component, route }">
        <transition name="fade" mode="out-in">
          <div :key="route.path">
            <component :is="Component" class="my-10" />
          </div>
        </transition>
      </router-view>
    </v-main>

    <Footer />
  </v-layout>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import { useAuthenticationStore } from '@/store/authentication';
import { useMerchantsStore } from '@/store/merchants';

const authStore = useAuthenticationStore();
const merchantsStore = useMerchantsStore();
const router = useRouter();

const logout = async () => {
  await authStore.signOut();
  router.push('/signin');
};
</script>
