<template>
  <v-dialog v-model="dialogState" max-width="500px">
    <v-card>
      <template #title><span class="text-primary">Send refund receipt</span></template>

      <v-card-text class="pb-0">
        <p class="mb-4">You can send a refund receipt to the customer by entering their email address below.</p>
        <p class="mb-6">Refund amount: {{ props.currency }} {{ (props.value / 100).toFixed(2) }}</p>
      </v-card-text>

      <v-form class="px-6 pb-6">
        <v-text-field
          v-model.trim="email"
          class="w-100"
          label="Email address"
          type="email"
          autocapitalize="none"
          autocomplete="email"
          clearable
          :error-messages="(v$.email.$dirty && v$.email.$invalid && v$.email.$silentErrors[0].$message.toString()) || ''"
        />
        <div class="w-100 d-flex justify-space-between align-center mt-4">
          <v-btn-nd class="" @click="dialogState = !dialogState">Close</v-btn-nd>
          <v-btn class="bg-primary on-primary" :loading="isLoading" :disabled="email.length === 0 || v$.$errors.length > 0" @click="sendRefundEmail()">
            Send email
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ErrorResponse } from '@tableyeti/merchant-service';
import useVuelidate from '@vuelidate/core';
import { helpers, required, email as emailValidator } from '@vuelidate/validators';
import { AxiosError } from 'axios';
import { reactive, ref } from 'vue';
import { useApplicationStore } from '@/store/application';
import { useTransactionsStore } from '@/store/transactions';

const transactionsStore = useTransactionsStore();
const applicationStore = useApplicationStore();

const dialogState = defineModel<boolean>({ required: true });
const props = defineProps({
  value: {
    type: Number,
    required: true,
  },
  currency: {
    type: String,
    required: true,
  },
  pspReference: {
    type: String,
    required: true,
  },
  refundPspReference: {
    type: String,
    required: true,
  },
});

const isLoading = ref(false);

const email = ref<string>('');

const v$ = useVuelidate(
  {
    email: {
      $autoDirty: true,
      required: helpers.withMessage('Required', required),
      email: helpers.withMessage('Invalid email address', emailValidator),
    },
  },
  reactive({
    email,
  }),
);

const sendRefundEmail = async () => {
  if (v$.value.$invalid) return;

  try {
    isLoading.value = true;
    await transactionsStore.sendRefundTransactionEmail(props.pspReference, email.value, props.refundPspReference);
    applicationStore.notifyUser({ message: 'Email sent successfully', type: 'success' });
    // Reset the form
    email.value = '';
    dialogState.value = false;
  } catch (error: unknown) {
    console.error(error);
    let message = 'An error occurred while sending the email.';
    if (error instanceof AxiosError) {
      const data = error.response?.data as ErrorResponse;
      if (data?.error === 'InactiveRecipientsError') {
        message = 'The email address is not active. Please enter a valid email address.';
      }
    }
    applicationStore.notifyUser({ message, type: 'error' });
  } finally {
    isLoading.value = false;
  }
};
</script>
