import { AddSitesPaymentMethodRequestPaymentMethodEnum } from '@tableyeti/merchant-service';
import { TransactionRefund, TransactionStatusEnum } from '@tableyeti/merchant-service/api.ts';
import { AxiosError } from 'axios';
import { endOfDay, format, parseISO, startOfDay, addHours, addDays, isToday } from 'date-fns';
import { defineStore } from 'pinia';
import { Amount } from '@/api/merchant-service/common-types';
import PaymentLinkApi from '@/api/merchant-service/payment-links';
import TransactionApi, { TransactionType, TransactionV1 } from '@/api/merchant-service/transaction';
import { useYetipayApi } from '@/composables/useYetipayApi';
import { useMerchantsStore } from '@/store/merchants';

const oldApi = new TransactionApi(() => useMerchantsStore().currentMerchant!.merchantId);
const paymentLinkApi = new PaymentLinkApi(() => useMerchantsStore().currentMerchant!.merchantId);

type FormattedTransactions = TransactionV1 & { type?: TransactionType; cardSummary?: string };

export type TransactionsParams = {
  dates?: Date[];
  siteId?: string;
  type?: TransactionType;
  pspReference?: string;
  paymentMethods?: AddSitesPaymentMethodRequestPaymentMethodEnum[];
  statuses?: TransactionStatusEnum[];
  cardSummary?: number;
};

type State = {
  transactionsParams: TransactionsParams;
  transactions: FormattedTransactions[] | undefined;
  paymentLinks: { [key: string]: string };
  transactionsLoading: boolean;
  transactionsError: string | undefined;
  transactionsNotAllowed: boolean;
  transactionsTotals: { totalsCount: number; totalsAmount: Amount; tipsAmount: Amount } | undefined;
  page: number;
  refundLoading: boolean;
  refundError: string | undefined;
  refundReceiptError: string | undefined;
  // Internal states, not meant to be used outside of the store
  _pagesLastKey: (string | undefined)[];
  _fetchedTotalPages: number | undefined;
};

const state = (): State => ({
  transactionsParams: {},
  transactions: undefined,
  paymentLinks: {},
  transactionsLoading: false,
  transactionsError: undefined,
  transactionsNotAllowed: false,
  transactionsTotals: undefined,
  page: 1,
  refundLoading: false,
  refundError: undefined,
  refundReceiptError: undefined,
  _pagesLastKey: [undefined],
  _fetchedTotalPages: undefined,
});

const finalPageKnown = ($this: State) => {
  return !!$this._fetchedTotalPages || !$this._pagesLastKey[$this._pagesLastKey.length - 1];
};
const finalPage = ($this: State) => {
  if ($this._fetchedTotalPages) return $this._fetchedTotalPages;
  if (!$this._pagesLastKey[$this._pagesLastKey.length - 1]) return $this._pagesLastKey.length - 1;
  return undefined;
};

export const isAfterMidnightButBeforeClosingTime = (closingTime: string) => {
  const now = new Date();
  const isAfterMidnight = now.getHours() < 12 && now.getHours() >= 0;
  const isBeforeCloseTime = now.getHours() < Number(closingTime.split(':')[0]);
  return isAfterMidnight && isBeforeCloseTime;
};

export const adjustToSalesDayClosingTime = (ogDate: Date, closingTime: string, skipDay?: boolean) => {
  // If after midnight but before closing time, we want to query the previous day's sales day
  if (isAfterMidnightButBeforeClosingTime(closingTime)) {
    ogDate = addDays(ogDate, -1);
  }
  const [hours] = closingTime.split(':').map(Number);

  // Adjust the date to the sales day closing time
  const newDate = addHours(startOfDay(ogDate), hours);
  return skipDay ? addDays(newDate, 1) : newDate;
};

const getters = {
  totalPages(this: State) {
    return this._fetchedTotalPages ?? this._pagesLastKey.length - (finalPageKnown(this) ? 1 : 0);
  },
  finalPageKnown(this: State) {
    return finalPageKnown(this);
  },
  isFinalPage(this: State) {
    return finalPageKnown(this) && finalPage(this) === this.page;
  },
};

const formatTransaction = (transaction: TransactionV1): FormattedTransactions => {
  let enableRefund = ['pending', 'captured', 'partially-refunded'].includes(transaction.status ?? 'captured');
  if (transaction.success === 'false') {
    enableRefund = false;
  }

  let status = transaction.status;
  if (!status) {
    status = transaction.success === 'true' ? TransactionStatusEnum.Captured : TransactionStatusEnum.Failed;
  }

  const refunds = transaction.refunds?.map(
    (refund: TransactionRefund) =>
      ({
        pspReference: refund.pspReference,
        bookingDate: format(parseISO(refund.bookingDate), 'yyy.MM.dd HH:mm:ss'),
        amount: refund.amount,
        reason: refund.reason ?? '-',
        success: refund.success,
      }) as TransactionRefund,
  );

  return {
    ...transaction,
    type: transaction.paymentLinkId ? 'Pay-by-link' : 'In-person',
    refunds,
    // TODO: chargebacks
    status,
    createdDate: format(parseISO(transaction.createdDate), 'yyy.MM.dd HH:mm:ss'),
    reason: transaction.success === 'true' ? undefined : transaction.reason,
    successBool: transaction.success === 'true',
    enableRefund,
    cardSummary: transaction?.relatedPaymentNotifications ? transaction?.relatedPaymentNotifications[0]?.additionalData?.cardSummary : undefined,
  };
};

export const prepareDatesForApi = (dates: Date[] | undefined, salesDayClosingTime?: string) => {
  if (dates?.length === 1 || (dates?.length === 2 && !dates[1])) {
    dates = [startOfDay(dates[0]), endOfDay(dates[0])];
  } else if (dates?.length === 2) {
    // without this, the end date time selects the current time, instead of the end of the day
    dates = [startOfDay(dates[0]), endOfDay(dates[1])];
  }

  // Adjust the dates to the sales day closing time if configured
  // i.e 01-01-24 00:00:00 -> 01-01-24 23:59:59 becomes (01-01-24 05:00 -> 02-01-24 05:00)
  if (dates && salesDayClosingTime) {
    dates = [adjustToSalesDayClosingTime(dates[0], salesDayClosingTime), adjustToSalesDayClosingTime(dates[1], salesDayClosingTime, true)];

    // If we're currently after midnight, but before close time, query current sales day rather than the current calendar day.
    if (isToday(dates![0]) && isAfterMidnightButBeforeClosingTime(salesDayClosingTime)) {
      dates = dates?.map((date) => addDays(date, -1));
    }
  }

  return dates
    ?.filter((d) => !!d)
    .map((date) => date.toISOString())
    .join(',');
};

const _loadPaymentLinks = async ($this: State) => {
  if (!$this.transactions || $this.transactions.length === 0) return;

  const paymentLinkIds = $this.transactions.filter((transaction) => transaction.paymentLinkId).map((transaction) => transaction.paymentLinkId!);

  if (paymentLinkIds.length === 0) return;

  const paymentLinks = await paymentLinkApi.getPaymentLinks(paymentLinkIds);

  $this.paymentLinks = paymentLinks.reduce((acc, link) => {
    const indexOfFirstDash = link.description?.indexOf('-');
    return {
      ...acc,
      // The description is in the format "Customer name - description"
      [link.linkId]: link?.description?.slice(indexOfFirstDash + 1)?.trim() || '',
    };
  }, {});
};

const _loadTransactions = async ($this: State, page = $this.page, finalPage = true) => {
  const { api, currentMerchantId } = useYetipayApi();
  const currentMerchant = useMerchantsStore().currentMerchant!;
  $this.transactionsLoading = true;
  try {
    const stringifiedCardSummary = `${$this.transactionsParams.cardSummary}`;
    const { items, lastKey } = await api
      .listTransaction(
        currentMerchantId,
        $this.transactionsParams.type,
        $this.transactionsParams.paymentMethods?.join(',') || undefined,
        undefined, // amount
        $this.transactionsParams.statuses?.join(',') || undefined,
        stringifiedCardSummary.length == 4 ? stringifiedCardSummary : undefined,
        // VueDatePicker sets the second date as null when only 1 is selected
        prepareDatesForApi($this.transactionsParams.dates, currentMerchant.settings?.salesDayClosingTime) || undefined,
        $this.transactionsParams.pspReference,
        $this.transactionsParams.siteId,
        $this._pagesLastKey[$this.page - 1],
      )
      .then((response) => response.data.data);
    if (finalPage) {
      $this.transactions = items?.map(formatTransaction) ?? [];
      // We want to load the payment links asynchronously to not block the main render of transactions
      _loadPaymentLinks($this);
    }
    $this._pagesLastKey[page] = lastKey;
    $this.transactionsNotAllowed = false;
  } catch (error: unknown) {
    console.error(error);
    if (error instanceof AxiosError) {
      if (error.response?.status === 403) {
        $this.transactionsNotAllowed = true;
      } else {
        $this.transactionsError = error.response?.data?.error ?? error?.message ?? 'Something went wrong';
      }
    } else {
      $this.transactionsError = 'Something went wrong';
    }
  } finally {
    if (finalPage) {
      $this.transactionsLoading = false;
    }
  }
};

const _fetchTotalPages = async ($this: State) => {
  const { api, currentMerchantId } = useYetipayApi();
  const currentMerchant = useMerchantsStore().currentMerchant!;

  try {
    const { totalPages } = await api
      .listTransaction(
        currentMerchantId,
        $this.transactionsParams.type,
        undefined, // payment method
        undefined, // amount
        undefined, // status
        undefined, // cardSummary
        // VueDatePicker sets the second date as null when only 1 is selected
        prepareDatesForApi($this.transactionsParams.dates, currentMerchant.settings?.salesDayClosingTime) || undefined,
        $this.transactionsParams.pspReference,
        $this.transactionsParams.siteId,
        $this._pagesLastKey[$this.page - 1],
        'true',
      )
      .then((response) => response.data.data);
    $this._fetchedTotalPages = totalPages;
  } catch (error: unknown) {
    console.error(error);
  }
};

const actions = {
  async refresh(this: State) {
    this.page = 1;
    this.transactions = [];
    this._pagesLastKey = [undefined];
    this._fetchedTotalPages = undefined;
    if (!this._fetchedTotalPages && this.transactionsParams.dates) {
      // We don't want to count pages for an infinite time range
      _fetchTotalPages(this); // can run without await as not crutial for the UI
    }
    await _loadTransactions(this);
  },
  async setPage(this: State, newPage: number) {
    this.page = newPage;
    let stepPage = Math.min(newPage, this._pagesLastKey.length - 1);
    do {
      await _loadTransactions(this, stepPage, stepPage === newPage);
    } while (stepPage++ != newPage);
  },
  async refundTransaction(this: State, pspReference: string, amount: Amount, emailToSendReceipt?: string) {
    const { api, currentMerchantId } = useYetipayApi();

    this.refundLoading = true;
    this.refundError = undefined;
    const transactionToRefund = this.transactions!.find((t) => t.pspReference === pspReference)!;
    transactionToRefund.enableRefund = false;
    try {
      const response = await api.refundTransaction(currentMerchantId, pspReference, { amount, emailToSendReceipt }).then((response) => response.data);
      if (response.error) {
        this.refundReceiptError = response.error;
      }
    } catch (error: unknown) {
      console.error(error);
      if (error instanceof AxiosError) {
        this.refundError = error.response?.data?.error || 'Something went wrong';
      }
      const leftInTransaction =
        transactionToRefund.amount.value - (transactionToRefund.refunds?.reduce((acc, r) => acc + r.amount.value, 0) ?? 0) - (amount?.value ?? 0);
      transactionToRefund.enableRefund = leftInTransaction > 0;
    } finally {
      this.refundLoading = false;
    }
  },
  async sendRefundTransactionEmail(this: State, pspReference: string, email: string, refundPspReference: string) {
    const { api, currentMerchantId } = useYetipayApi();

    await api.emailRefundReceipt(currentMerchantId, pspReference, { email, refundPspReference });
  },
  async getTransactionTotals(this: State) {
    if (!this.transactionsParams.siteId) {
      this.transactionsTotals = undefined;
      return;
    }

    try {
      const result = await oldApi.getTotals(this.transactionsParams.siteId!, startOfDay(new Date()));
      this.transactionsTotals = result;
    } catch (error: unknown) {
      console.error(error);
    }
  },
};

export const useTransactionsStore = defineStore('transactions', {
  state,
  getters,
  actions,
});
