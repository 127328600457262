<template>
  <v-navigation-drawer v-model="isDrawerOpen" location="right" temporary :width="xs ? 350 : 422">
    <div class="d-flex flex-column pa-4 h-100 overflow-y-auto">
      <div class="d-flex justify-space-between align-center mb-4">
        <h3>Filters</h3>
        <v-btn variant="text" icon="mdi-window-close" @click.stop="isDrawerOpen = !isDrawerOpen" />
      </div>
      <div class="d-flex flex-column ga-2 flex-grow-1">
        <div class="d-flex flex-column ga-2">
          <VueDatePicker
            v-model="dates"
            locale="en-UK"
            format="dd/MM/yyyy"
            placeholder="Filter by date(s)"
            :range="{ noDisabledRange: true }"
            :max-date="new Date()"
            :enable-time-picker="false"
            :preset-dates="datePickerPresetRanges"
            position="right"
            class="mb-4"
          />
          <p v-if="salesDayClosingTime" class="text-body-2 px-1 mb-5">
            <v-icon>mdi-timer-refresh-outline</v-icon>
            Your trading hours are set to {{ formatMerchantHours(salesDayClosingTime) }}. We'll use these times when searching for transactions.
          </p>
          <v-autocomplete
            v-model="statuses"
            multiple
            label="Transaction Status"
            :items="
              Object.values(TransactionStatusEnum).map((status) => ({
                title: formatTextWithDashes(status),
                value: status,
              }))
            "
            clearable
          />
          <v-autocomplete v-model="type" label="Type" :items="['In-person', 'Pay-by-link']" clearable />
          <v-autocomplete
            v-model="paymentMethods"
            multiple
            label="Payment method"
            :items="
              Object.values(AddSitesPaymentMethodRequestPaymentMethodEnum).map((method) => ({
                title: getPaymentMethodPublicName(method),
                value: method,
              }))
            "
            clearable
          />
          <v-text-field v-model="pspReference" label="PSP reference" clearable @input="() => (pspReference = pspReference?.toUpperCase())" />
          <v-text-field
            v-model="cardSummary"
            clearable
            label="Last 4 digits"
            :error-messages="cardSummary && cardSummary.toString().length !== 4 ? 'Must be 4 digits long' : ''"
          />

          <v-btn variant="outlined" class="mt-3 mb-2" @click="handleResetBtn">Reset filters</v-btn>
          <v-btn @click="handleDoneBtn">Done</v-btn>
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import { AddSitesPaymentMethodRequestPaymentMethodEnum } from '@tableyeti/merchant-service';
import { TransactionStatusEnum } from '@tableyeti/merchant-service/api.ts';
import { endOfDay, endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek, startOfYear, subDays, subMonths, subWeeks } from 'date-fns';
import { ref, watch } from 'vue';
import { useDisplay } from 'vuetify';
import { TransactionType } from '@/api/merchant-service/transaction.ts';
import { useMerchantsStore } from '@/store/merchants';
import { useTransactionsStore } from '@/store/transactions.ts';
import { formatTextWithDashes, getPaymentMethodPublicName } from '@/utils/format.ts';

const { xs } = useDisplay();

const { transactionsParams } = useTransactionsStore();

const merchantsStore = useMerchantsStore();

const pspReference = ref<string | undefined>(transactionsParams.pspReference);
const dates = ref<Date[]>(transactionsParams.dates || []);
const paymentMethods = ref<AddSitesPaymentMethodRequestPaymentMethodEnum[] | undefined>(transactionsParams.paymentMethods);
const statuses = ref<TransactionStatusEnum[] | undefined>(transactionsParams.statuses);
const type = ref<TransactionType | undefined>(transactionsParams.type || undefined);
const cardSummary = ref<number | undefined>(transactionsParams.cardSummary || undefined);

watch(
  () => transactionsParams,
  () => {
    type.value = transactionsParams.type || undefined;
    pspReference.value = transactionsParams.pspReference || undefined;
    dates.value = transactionsParams.dates || [];
    cardSummary.value = transactionsParams.cardSummary || undefined;
    statuses.value = transactionsParams.statuses || undefined;
    paymentMethods.value = transactionsParams.paymentMethods || undefined;
  },
  { deep: true },
);

const isDrawerOpen = defineModel<boolean>();
const salesDayClosingTime = merchantsStore.currentMerchant?.settings?.salesDayClosingTime;

const startOfDayToday = startOfDay(new Date());
const endOfDayToday = endOfDay(new Date());

const datePickerPresetRanges = ref([
  { label: 'Today', value: [startOfDayToday, endOfDayToday] },
  { label: 'Yesterday', value: [subDays(startOfDayToday, 1), subDays(endOfDayToday, 1)] },
  { label: 'This week', value: [startOfWeek(startOfDayToday, { weekStartsOn: 1 }), endOfDayToday] },
  { label: 'Last week', value: [startOfWeek(subWeeks(startOfDayToday, 1), { weekStartsOn: 1 }), endOfWeek(subWeeks(endOfDayToday, 1), { weekStartsOn: 1 })] },
  { label: 'This month', value: [startOfMonth(startOfDayToday), endOfDayToday] },
  { label: 'Last month', value: [startOfMonth(subMonths(startOfDayToday, 1)), endOfMonth(subMonths(endOfDayToday, 1))] },
  { label: 'Last 90 days', value: [subDays(startOfDayToday, 90), endOfDayToday] },
  { label: 'Last 6 months', value: [startOfMonth(subMonths(startOfDayToday, 6)), endOfMonth(subMonths(endOfDayToday, 1))] },
  { label: 'This year', value: [startOfYear(startOfDayToday), endOfDayToday] },
]);

const handleResetBtn = () => {
  pspReference.value = undefined;
  dates.value = [startOfDayToday, endOfDayToday];
  cardSummary.value = undefined;
  statuses.value = undefined;
  paymentMethods.value = undefined;
  type.value = undefined;
};

const formatMerchantHours = (hours: string) => {
  const hour = hours.split(':')[0].replace('0', '');
  return `${hour}am - ${hour}am`;
};

const handleDoneBtn = () => {
  if (!/[a-z]/.exec(pspReference.value ?? '')) {
    // To set back today date when pspReference is empty for UX
    if (!pspReference.value && !transactionsParams.dates) {
      transactionsParams.dates = [startOfDay(new Date()), endOfDay(new Date())];
    }
    transactionsParams.pspReference = pspReference.value;
  }

  if (transactionsParams.dates !== dates.value) {
    transactionsParams.dates = dates.value?.filter((date) => !!date);
  }
  if (transactionsParams.cardSummary !== cardSummary.value) {
    transactionsParams.cardSummary = cardSummary.value || undefined;
  }
  if (transactionsParams.statuses !== statuses.value) {
    transactionsParams.statuses = statuses.value || undefined;
  }
  if (transactionsParams.paymentMethods !== paymentMethods.value) {
    transactionsParams.paymentMethods = paymentMethods.value || undefined;
  }
  if (transactionsParams.type !== type.value) {
    transactionsParams.type = type.value || undefined;
  }

  isDrawerOpen.value = !isDrawerOpen.value;
};
</script>

<style scoped>
.v-navigation-drawer :deep(.v-navigation-drawer__content) {
  overflow: visible !important;
}
</style>
