<template>
  <v-row class="fill-height py-4 overflow-visible">
    <FormErrorBanner :error-message="errorMessage" />
    <v-carousel
      v-model="currentItemIndex"
      :continuous="false"
      :touch="false"
      class="overflow-visible"
      hide-delimiters
      :show-arrows="false"
      direction="vertical"
      progress="white"
    >
      <slot></slot>
    </v-carousel>
  </v-row>
</template>

<script setup lang="ts">
import { computed, nextTick, provide, reactive, ref } from 'vue';
import FormErrorBanner from '@/components/Form/FormErrorBanner.vue';

const currentItemIndex = ref(0);

const errorMessage = ref<string | undefined>(undefined);

interface CarouselItemBase {
  id: string;
  name?: string;
  disabled?: boolean;
  onFocus: () => void;
}

interface CarouselItem extends CarouselItemBase {
  index: number;
  isFirst: boolean;
  isLast: boolean;
}

export interface CarouselContext {
  currentItem: number;
  items: CarouselItem[];
  // TODO: look into default register and items behaviour on v-carousel
  registerItem(item: CarouselItemBase): void;
  updateItem(itemBase: CarouselItemBase): void;
  goToItem(destination: number): void;
}

const initItems = (items: CarouselItemBase[]): CarouselItem[] =>
  items
    .filter((item) => !item.disabled)
    .map((item, index, array) => {
      return {
        ...item,
        index,
        isFirst: index === 0,
        isLast: index === array.length - 1,
      };
    });

const focusOnItem = (index: number) => {
  nextTick(() => {
    carouselContext.items[index].onFocus();
  });
};

const goToItem = (destinationIndex: number) => {
  const restrictedIndex = Math.max(0, Math.min(destinationIndex, carouselContext.items.length - 1));
  setTimeout(() => {
    currentItemIndex.value = restrictedIndex;
    focusOnItem(restrictedIndex);
  }, 100);
};

const goToItemByName = (destinationName: string) => {
  const destinationIndex = carouselContext.items.findIndex((item) => item.name === destinationName);
  if (destinationIndex >= 0) {
    goToItem(destinationIndex);
  }
};

const baseItems = ref<CarouselItemBase[]>([]);

const items = computed(() => initItems(baseItems.value));

const carouselContext = reactive<CarouselContext>({
  currentItem: currentItemIndex.value,
  get items() {
    return items.value;
  },
  registerItem(item) {
    baseItems.value = [...baseItems.value, item];
  },
  updateItem: (item: CarouselItemBase) => {
    const index = baseItems.value.findIndex((i) => i.id === item.id);
    if (index >= 0) {
      baseItems.value[index] = item;
    }
    baseItems.value = [...baseItems.value];
  },
  goToItem,
});

provide('carouselContext', carouselContext);

const setError = (message: string, field?: string) => {
  errorMessage.value = message;
  if (field) {
    goToItemByName(field);
  }
};

const clearError = () => {
  errorMessage.value = undefined;
};

const next = () => {
  goToItem(currentItemIndex.value + 1);
};

const prev = () => {
  goToItem(currentItemIndex.value - 1);
};

defineExpose({ next, prev, setError, clearError });
</script>
