<template>
  <v-row class="justify-space-between mt-6">
    <div>
      <v-btn color="primary" rounded :disabled="disabled" :loading="nextLoading" @click="handleNextClick">{{ nextLabel }}</v-btn>
      <label v-if="!disabled && enterSubmitEnabled" class="mx-2 text-grey">or Enter ↵</label>
    </div>
    <v-btn v-if="previousLabel" rounded @click="handlePreviousClick">
      <span>{{ previousLabel }}</span>
    </v-btn>
  </v-row>
</template>

<script setup lang="ts">
const disabled = defineModel('disabled', { type: Boolean, required: false, default: false });

defineProps({
  nextLabel: {
    type: String,
    required: false,
    default: 'Next',
  },
  nextLoading: {
    type: Boolean,
    required: false,
    default: false,
  },
  previousLabel: {
    type: String,
    required: false,
    default: undefined,
  },
  enterSubmitEnabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(['nextClick', 'previousClick']);

const handleNextClick = () => {
  emit('nextClick');
};

const handlePreviousClick = () => {
  emit('previousClick');
};
</script>
