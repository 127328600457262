import BigNumber from 'bignumber.js';
import { Amount } from '@/api/merchant-service/common-types';

export const displayAmount = (amount: Amount) => {
  return new BigNumber(amount.value).dividedBy(100).toNumber().toLocaleString('en-UK', {
    style: 'currency',
    currency: amount.currency,
  });
};

export const getPaymentMethodPublicName = (paymentMethod: string) => {
  switch (paymentMethod) {
    case 'amex':
      return 'American Express';
    case 'mc':
      return 'Mastercard';
    case 'visa':
      return 'Visa';
    case 'maestro':
      return 'Maestro';
    case 'bcmc':
      return 'Bancontact';
    case 'cup':
      return 'Union Pay';
    case 'discover':
      return 'Discover';
    case 'girocard':
      return 'Girocard';
    default:
      return paymentMethod;
  }
};

export const formatTextWithDashes = (text: string) =>
  text
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
