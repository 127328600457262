<template>
  <v-app>
    <router-view></router-view>
    <transition name="fade" mode="out-in">
      <div v-if="showLoadingBar" class="d-flex flex-column fill-height w-100 position-absolute">
        <v-progress-linear id="app-loading-bar" color="primary" indeterminate />
        <div class="d-flex justify-center fill-height"><v-img id="app-loading-background" src="@/assets/img/splash.png" max-width="400" /></div>
      </div>
    </transition>
    <Toast />
  </v-app>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useApplicationStore } from '@/store/application';

const applicationStore = useApplicationStore();
const showLoadingBar = computed(() => applicationStore.isApplicationLoading);
</script>

<style>
@import '@/assets/css/variables.css';
@import '@/assets/css/transitions.css';
</style>
