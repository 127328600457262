<template>
  <FormCarousel ref="carouselControls">
    <!-- Field 1: shortcode -->
    <FormCarouselItem name="shortcode" :disable-next-if="v$.shortcode.$invalid" @focus="shortcodeFieldRef?.focus">
      <FormQuestionLabel>Let's start with your Yetipay shortcode</FormQuestionLabel>
      <v-row class="justify-center justify-sm-start mt-2">
        <v-otp-input
          ref="shortcodeFieldRef"
          v-model="form.shortcode"
          length="6"
          autofocus
          input-classes="otp-input"
          variant="underlined"
          base-color="white"
          :error-messages="v$.shortcode.$errors.map((e) => e.$message.toString())"
          @blur="v$.shortcode.$touch()"
        />
      </v-row>
    </FormCarouselItem>

    <!-- Field 2: email address -->
    <FormCarouselItem name="email" :disable-next-if="v$.email.$invalid" :next-loading="submitting" @focus="emailFieldRef?.focus" @next="submit">
      <FormQuestionLabel>What's your email address?</FormQuestionLabel>
      <v-row class="mt-4">
        <FormTextInput
          ref="emailFieldRef"
          v-model="form.email"
          type="email"
          :error-messages="v$.email.$errors.map((e: ErrorObject) => e.$message.toString())"
          @blur="v$.email.$touch()"
        />
      </v-row>
    </FormCarouselItem>
  </FormCarousel>
</template>

<script setup lang="ts">
import useVuelidate, { ErrorObject } from '@vuelidate/core';
import { email, helpers, numeric, required, minLength, maxLength } from '@vuelidate/validators';
import { reactive, ref } from 'vue';
import { VOtpInput } from 'vuetify/components';
import FormCarousel from '@/components/Form/FormCarousel.vue';
import FormTextInput from '@/components/Form/FormTextInput.vue';
import { OnboardingError, useOnboardingStore } from '@/store/onboarding';

const onboardingStore = useOnboardingStore();
const carouselControls = ref<InstanceType<typeof FormCarousel> | null>(null);

const shortcodeFieldRef = ref<typeof VOtpInput | null>(null);
const emailFieldRef = ref<typeof FormTextInput | null>(null);

const form = reactive({
  shortcode: '',
  email: '',
});

const rules = {
  shortcode: {
    required: helpers.withMessage('Shortcode is required', required),
    numeric: helpers.withMessage('Shortcode must be numeric', numeric),
    minLength: helpers.withMessage('Shortcode must be 6 digits', minLength(6)),
    maxLength: helpers.withMessage('Shortcode must be 6 digits', maxLength(6)),
  },
  email: {
    required: helpers.withMessage('Email is required', required),
    email: helpers.withMessage('Email must be valid', email),
  },
};

const v$ = useVuelidate(rules, form);

const submitting = ref<boolean>(false);

const submit = async () => {
  carouselControls.value?.clearError();
  v$.value.$touch();
  if (v$.value.$invalid) {
    carouselControls.value?.setError(v$.value.$errors[0]?.$message.toString() || 'Please ensure all fields are filled correctly');
    return;
  }

  try {
    submitting.value = true;
    const draftMerchant = await onboardingStore.fetchOrCreateDraftMerchant(form.shortcode, form.email);
    if (!draftMerchant) {
      carouselControls.value?.setError('Something went wrong');
    }
  } catch (error) {
    if (error instanceof OnboardingError) {
      carouselControls.value?.setError(error.message, error.field ?? 'shortcode');
    } else {
      carouselControls.value?.setError('Something went wrong', 'shortcode');
    }
  } finally {
    submitting.value = false;
  }
};
</script>
