import { createI18n, I18n, useI18n } from 'vue-i18n';
import { en } from 'vuetify/locale';
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n';
import enYeti from '@/assets/i18n/en.json';

const messages = {
  en: { $vuetify: { ...en }, ...enYeti },
};

type MessageSchema = typeof messages.en;

export const i18n = createI18n<MessageSchema, 'en'>({
  legacy: false, // Vuetify does not support the legacy mode of vue-i18n
  globalInjection: false, // we favor the composition API
  locale: window.navigator.language,
  fallbackLocale: 'en',
  messages,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-empty-object-type
export const i18nAdapter = createVueI18nAdapter({ i18n: i18n as unknown as I18n<any, {}, {}, string, false>, useI18n });
