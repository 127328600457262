<template>
  <v-alert v-if="transactionsStore.transactionsError && transactionsStore.transactionsError !== 'Too many pages scanned'" type="error">
    Issue occurred while gathering your transactions. Please refresh or contact <a href="mailto:help@yetipay.me">help@yetipay.me</a>.
  </v-alert>

  <v-skeleton-loader v-else-if="!transactionsStore.transactions" type="table" />

  <div v-else>
    <v-alert v-if="transactionsStore.transactionsError === 'Too many pages scanned'" type="error" density="compact" class="mb-3">
      We could not find results with your search criteria. Please try to reduce the time range, or contact <a href="mailto:help@yetipay.me">help@yetipay.me</a>.
    </v-alert>
    <div class="w-100 d-flex flex-column flex-sm-row ga-4 align-center justify-space-between mb-1 mb-sm-0">
      <h3 class="text-primary">
        <span class="on-background-variant">Total today: </span>
        <span v-if="transactionsStore.transactionsTotals?.totalsAmount">
          {{ displayAmount(transactionsStore.transactionsTotals?.totalsAmount) }}
          <span v-if="transactionsStore.transactionsTotals?.tipsAmount.value > 0">
            ({{ displayAmount(transactionsStore.transactionsTotals?.tipsAmount) }} tips)
          </span>
        </span>
        <span v-else class="mx-1">-</span>
        <v-tooltip :text="transactionsTotalsTooltip!" :disabled="!transactionsTotalsTooltip" location="bottom">
          <template #activator="{ props }">
            <span v-bind="props"><v-icon v-if="transactionsTotalsTooltip" color="primary">mdi-information-outline</v-icon></span>
          </template>
        </v-tooltip>
      </h3>

      <SiteSelector v-model="transactionsStore.transactionsParams.siteId" class="w-100 mt-0 mt-sm-4" style="max-width: 250px" />
    </div>

    <FiltersDrawer v-model="isDrawerOpen" />

    <v-alert v-if="transactionsStore.transactionsNotAllowed" type="warning" variant="outlined" density="compact">
      Please select a site to view transactions.
    </v-alert>

    <v-data-table
      :headers="transactionsTableHeaders"
      :items="transactionsStore.transactions"
      :loading="transactionsStore.transactionsLoading"
      no-data-text="No transactions for the selected search criteria."
      :sort-by="[{ key: 'createdDate', order: 'desc' }]"
      item-value="pspReference"
      items-per-page="-1"
      :expanded="[]"
      show-expand
      expand-on-click
    >
      <template #top>
        <div class="d-flex justify-space-between ga-2 mt-1 flex-column flex-sm-row">
          <FilterBadges />

          <div class="d-flex ga-2 justify-end">
            <v-tooltip location="bottom" :disabled="!reportDisabled">
              <template #activator="{ props }">
                <div v-bind="props">
                  <v-btn
                    variant="text"
                    size="default"
                    color="secondary"
                    :disabled="reportDisabled"
                    :loading="reportLoading"
                    icon="mdi-download"
                    @click="downloadReport"
                  />
                </div>
              </template>
              {{ reportDisabledText }}
            </v-tooltip>
            <v-btn variant="text" size="default" color="secondary" icon="mdi-refresh" @click="transactionsStore.refresh" />

            <v-badge :content="paramsCount" color="primary" offset-y="5" offset-x="5">
              <v-btn variant="text" size="default" color="secondary" icon="mdi-filter-cog" @click.stop="isDrawerOpen = !isDrawerOpen" />
            </v-badge>
          </div>
        </div>
      </template>

      <template #expanded-row="{ columns, item }">
        <td :colspan="columns.length" class="pa-4">
          <div class="d-flex flex-grow-1 d-flex flex-column flex-md-row ga-md-4">
            <div class="d-flex flex-column">
              <p>
                <b>Transaction ID:</b> <kbd>{{ item.transactionId }}</kbd>
              </p>
              <p><b>Merchant Reference:</b> {{ item.adyenMerchantReference }}</p>
              <p v-if="!item.successBool"><b>Reason:</b> {{ item.reason }}</p>
            </div>

            <div v-if="item.paymentLinkId" class="d-flex flex-column flex-grow-1">
              <p>
                <b>Payment Link ID:</b> <kbd>{{ item.paymentLinkId }}</kbd>
              </p>
              <p><b>Payment reference:</b> {{ transactionsStore.paymentLinks[item.paymentLinkId] || '-' }}</p>
            </div>
          </div>

          <div v-if="item.refunds">
            <p class="font-weight-bold text-uppercase text-primary mt-3">Refunds:</p>
            <v-table density="compact" hover>
              <template #default>
                <thead>
                  <tr>
                    <th class="text-left">PSP Reference</th>
                    <th class="text-left">Booking date</th>
                    <th class="text-left">Amount</th>
                    <th class="text-left">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="refund in item.refunds" :key="refund.pspReference">
                    <td>
                      <kbd>{{ refund.pspReference }}</kbd>
                    </td>
                    <td>{{ refund.bookingDate }}</td>
                    <td>{{ displayAmount(refund.amount, true) }}</td>
                    <td>
                      <v-btn-nd
                        size="small"
                        variant="tonal"
                        @click="
                          refundSelectedForEmail = refund;
                          refundMainPspReference = item.pspReference;
                          refundEmailDialog = true;
                        "
                        >Send email</v-btn-nd
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-table>
          </div>
        </td>
      </template>
      <template #item.pspReference="{ item }">
        <kbd>{{ item.pspReference }}</kbd>
      </template>
      <template #item.paymentMethod="{ item }">
        <PaymentMethod v-model="item.paymentMethod" />
      </template>
      <template #item.cardSummary="{ item }">
        <kbd><sup>****</sup>{{ item.cardSummary }}</kbd>
      </template>
      <template #item.amount="{ item }">
        {{ displayAmount(item.amount) }}
      </template>
      <template #item.tip="{ item }">
        {{ displayAmount(item.includedTipAmount ?? { value: 0, currency: item.amount.currency }) }}
      </template>
      <template #item.status="{ item }">
        <span class="text-uppercase">{{ item.status }}</span>
      </template>
      <template #item.actions="{ item }">
        <v-btn-nd
          :disabled="!item.enableRefund"
          @click="
            transactionSelectedForRefund = item;
            refundConfirmDialog = true;
          "
          >Refund</v-btn-nd
        >
      </template>
      <template #bottom></template>
    </v-data-table>

    <v-pagination
      v-model="transactionsStore.page"
      :length="transactionsStore.totalPages"
      :disabled="transactionsStore.transactionsLoading"
      color="secondary"
      rounded="sm"
    >
      <template #next="{ disabled, onClick }">
        <v-btn-nd v-if="!transactionsStore.finalPageKnown" disabled icon="mdi-dots-horizontal" variant="text" size="default" />
        <v-btn-nd :disabled="disabled || transactionsStore.isFinalPage" icon="mdi-chevron-right" variant="text" size="default" @click="onClick" />
      </template>
    </v-pagination>
  </div>

  <RefundDialog v-model="refundConfirmDialog" :transaction="transactionSelectedForRefund" />
  <EmailRefundDialog
    v-model="refundEmailDialog"
    :value="refundSelectedForEmail?.amount.value || 0"
    :currency="refundSelectedForEmail?.amount.currency || 'GBP'"
    :psp-reference="refundMainPspReference || ''"
    :refund-psp-reference="refundSelectedForEmail?.pspReference || ''"
  />
  <DownloadDialog v-model="reportDownloadDialog" :report-id="reportId" />
</template>

<script setup lang="ts">
import { AddSitesPaymentMethodRequestPaymentMethodEnum, TransactionRefund } from '@tableyeti/merchant-service';
import { TransactionStatusEnum } from '@tableyeti/merchant-service/api.ts';
import BigNumber from 'bignumber.js';
import { differenceInDays, endOfDay, startOfDay } from 'date-fns';
import { ref, onBeforeMount, onMounted, watch, computed } from 'vue';
import { useRoute } from 'vue-router';
import { Amount } from '@/api/merchant-service/common-types';
import { TransactionType, TransactionV1 } from '@/api/merchant-service/transaction';
import { useTransactionParams } from '@/composables/useTransactionParams.ts';
import { useYetipayApi } from '@/composables/useYetipayApi.ts';
import { useMerchantsStore } from '@/store/merchants';
import { useTransactionsStore, prepareDatesForApi } from '@/store/transactions';
const route = useRoute();
const transactionsStore = useTransactionsStore();
const merchantsStore = useMerchantsStore();
const { paramsCount } = useTransactionParams();

const transactionsTableHeaders = [
  { title: 'Type', value: 'type' },
  { title: 'PSP Reference', value: 'pspReference' },
  { title: 'Site', value: 'siteName' },
  { title: 'Status', value: 'status' },
  { title: 'Amount', value: 'amount' },
  { title: 'Tip', value: 'tip' },
  { title: 'Card summary', value: 'cardSummary' },
  { title: 'Payment Method', value: 'paymentMethod' },
  { title: 'Created Date', value: 'createdDate' },
  { title: 'Actions', value: 'actions' },
];

const isDrawerOpen = ref(false);

const transactionSelectedForRefund = ref<TransactionV1 | undefined>(undefined);
const refundConfirmDialog = ref(false);

onBeforeMount(() => {
  if (transactionsStore.transactions) {
    // In the case where the store is already initialized, we don't want to override the params
    saveParamsToUrl();
    transactionsStore.refresh();
  } else {
    // Changing the params here will trigger a refresh (see the watch below)
    if (route.query.psp) {
      transactionsStore.transactionsParams.pspReference = route.query.psp as string;
      // Reset remaining filters since psp search is specific to a single trx
      transactionsStore.transactionsParams.type = undefined;
      transactionsStore.transactionsParams.statuses = undefined;
      transactionsStore.transactionsParams.cardSummary = undefined;
      transactionsStore.transactionsParams.paymentMethods = undefined;
      return;
    }
    if (route.query.type) {
      const type = route.query.type as TransactionType;
      transactionsStore.transactionsParams.type = type;
    }
    if (route.query.statuses) {
      const statuses = (route.query.statuses as string).split(',') as TransactionStatusEnum[];
      transactionsStore.transactionsParams.statuses = statuses;
    }
    if (route.query.cardSummary) {
      const cardSummary = route.query.cardSummary as string;
      transactionsStore.transactionsParams.cardSummary = +cardSummary;
    }
    if (route.query.paymentMethods) {
      const paymentMethods = (route.query.paymentMethods as string).split(',') as AddSitesPaymentMethodRequestPaymentMethodEnum[];
      transactionsStore.transactionsParams.paymentMethods = paymentMethods;
    }
    if (route.query.siteId) {
      transactionsStore.transactionsParams.siteId = route.query.siteId as string;
    }
    if (route.query.dates) {
      transactionsStore.transactionsParams.dates = (route.query.dates as string).split(',').map((date: string) => new Date(date));
    } else {
      transactionsStore.transactionsParams.dates = [startOfDay(new Date()), endOfDay(new Date())]; // Today
    }
  }
});

watch(
  () => transactionsStore.page,
  () => transactionsStore.setPage(transactionsStore.page),
);

onMounted(() => transactionsStore.getTransactionTotals());
watch(
  () => transactionsStore.transactionsParams.siteId,
  () => transactionsStore.getTransactionTotals(),
);

watch(
  () => transactionsStore.transactionsParams,
  () => {
    if (transactionsStore.transactionsParams.pspReference) {
      transactionsStore.transactionsParams.type = undefined;
      transactionsStore.transactionsParams.dates = undefined;
      transactionsStore.transactionsParams.paymentMethods = undefined;
      transactionsStore.transactionsParams.statuses = undefined;
      transactionsStore.transactionsParams.cardSummary = undefined;
    }
    saveParamsToUrl();
    transactionsStore.refresh();
  },
  { deep: true },
);

const transactionsTotalsTooltip = computed(() => {
  if (!transactionsStore.transactionsParams.siteId) {
    return 'Select a site to view the total transactions for today.';
  } else if (!transactionsStore.transactionsTotals) {
    return undefined; // still loading
  }
  if (transactionsStore.transactionsTotals.totalsCount === 0) {
    return 'No transactions today';
  }
  const { salesDayClosingTime } = merchantsStore.currentMerchant?.settings || {};
  return `Sum of all transactions since ${salesDayClosingTime ?? 'midnight'}`;
});

const saveParamsToUrl = () => {
  const queryParams: string[] = [];
  if (transactionsStore.transactionsParams.dates && transactionsStore.transactionsParams.dates.length > 0) {
    // VueDatePicker sets the second date as null when only 1 is selected
    queryParams.push(
      `dates=${transactionsStore.transactionsParams.dates
        .filter((d) => !!d)
        .map((date: Date) => date.toISOString())
        .join(',')}`,
    );
  }
  if (transactionsStore.transactionsParams.type) {
    queryParams.push(`type=${transactionsStore.transactionsParams.type}`);
  }
  if (transactionsStore.transactionsParams.siteId) {
    queryParams.push(`siteId=${transactionsStore.transactionsParams.siteId}`);
  }
  if (transactionsStore.transactionsParams.statuses) {
    queryParams.push(`statuses=${transactionsStore.transactionsParams.statuses.join(',')}`);
  }
  if (transactionsStore.transactionsParams.paymentMethods) {
    queryParams.push(`paymentMethods=${transactionsStore.transactionsParams.paymentMethods.join(',')}`);
  }
  if (transactionsStore.transactionsParams.cardSummary && /^\d{4}$/.test(`${transactionsStore.transactionsParams.cardSummary}`)) {
    queryParams.push(`cardSummary=${transactionsStore.transactionsParams.cardSummary}`);
  }
  if (transactionsStore.transactionsParams.pspReference) {
    queryParams.push(`psp=${transactionsStore.transactionsParams.pspReference}`);
  }
  history.pushState({}, '', route.path + (queryParams.length > 0 ? '?' + queryParams.join('&') : ''));
};

const displayAmount = (amount: Amount, invert = false) => {
  return new BigNumber(amount.value)
    .dividedBy(100)
    .multipliedBy(invert ? -1 : 1)
    .toNumber()
    .toLocaleString('en-UK', {
      style: 'currency',
      currency: amount.currency,
    });
};

const reportLoading = ref(false);
const reportDisabled = computed(() => {
  const noResult = transactionsStore.transactions?.length === 0;
  const noDatesNorPsp = !transactionsStore.transactionsParams.dates && !transactionsStore.transactionsParams.pspReference;
  const bothDatesAndPsp = !!transactionsStore.transactionsParams.dates && !!transactionsStore.transactionsParams.pspReference;
  const datesTooFarApart =
    transactionsStore.transactionsParams.dates?.length === 2 &&
    differenceInDays(transactionsStore.transactionsParams.dates[1], transactionsStore.transactionsParams.dates[0]) > 186;
  return noResult || reportLoading.value || noDatesNorPsp || bothDatesAndPsp || datesTooFarApart;
});
const reportDisabledText = computed(() => {
  if (reportLoading.value) {
    return 'Generating report...';
  }
  if (transactionsStore.transactionsLoading) {
    return 'Loading transactions...';
  }
  if (transactionsStore.transactions?.length === 0) {
    return 'No transactions to report';
  }
  if (!transactionsStore.transactionsParams.dates && !transactionsStore.transactionsParams.pspReference) {
    return 'Select a date range or search by PSP reference to generate a report';
  }
  if (!!transactionsStore.transactionsParams.dates && !!transactionsStore.transactionsParams.pspReference) {
    return 'Select either a date range or search by PSP reference to generate a report';
  }
  if (
    transactionsStore.transactionsParams.dates?.length === 2 &&
    differenceInDays(transactionsStore.transactionsParams.dates[1], transactionsStore.transactionsParams.dates[0]) > 186
  ) {
    return 'Date range too large. Please select a range of 6 months or less.';
  }
  return 'Generating report...';
});

const reportId = ref<string | undefined>(undefined);
const reportDownloadDialog = ref(false);
const downloadReport = async () => {
  const { api, currentMerchantId } = useYetipayApi();
  const currentMerchant = useMerchantsStore().currentMerchant!;
  reportLoading.value = true;
  try {
    const result = await api.createReport(currentMerchantId, {
      dataType: 'transactions',
      dates: prepareDatesForApi(transactionsStore.transactionsParams.dates, currentMerchant.settings?.salesDayClosingTime),
      siteId: transactionsStore.transactionsParams.siteId || undefined,
      psp: transactionsStore.transactionsParams.pspReference,
      type: transactionsStore.transactionsParams.type,
      paymentMethods: transactionsStore.transactionsParams.paymentMethods?.join(',') || undefined,
      statuses: transactionsStore.transactionsParams.statuses?.join(',') || undefined,
      cardSummary: transactionsStore.transactionsParams.cardSummary ? `${transactionsStore.transactionsParams.cardSummary}` : undefined,
    });
    reportId.value = result.data.reportId;
    reportDownloadDialog.value = true;
  } catch (e) {
    console.error(e);
  } finally {
    reportLoading.value = false;
  }
};

const refundMainPspReference = ref<string | undefined>(undefined);
const refundSelectedForEmail = ref<TransactionRefund | undefined>(undefined);
const refundEmailDialog = ref(false);
</script>
