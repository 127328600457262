import { Transaction } from '@tableyeti/merchant-service/api.ts';
import { Amount } from './common-types';
import { myAxios } from '@/plugins/api';

export type TransactionType = 'Pay-by-link' | 'In-person';

export interface TransactionV1 extends Transaction {
  successBool?: boolean;
  enableRefund?: boolean; // Additional UI field
}

export interface TransactionReducedV1 {
  pspReference: string;
  paymentMethod: string;
  paymentLinkId: string;
  transactionId: string;
  adyenMerchantReference: string;
}

class TransactionApi {
  private readonly merchantId: () => string;
  constructor(merchantId: () => string) {
    this.merchantId = merchantId;
  }

  public async getTransactionsFromPsp(pspReferences: string[]): Promise<TransactionReducedV1[]> {
    return myAxios.post(`/v1/merchants/${this.merchantId()}/transactions/psp`, { pspReferences }).then((response) => response.data.data);
  }

  public async getTotals(siteId: string, startDate: Date): Promise<{ totalsCount: number; totalsAmount: Amount; tipsAmount: Amount }> {
    return myAxios
      .get(`/v1/merchants/${this.merchantId()}/transactions/totals`, {
        params: { siteId, startDate: startDate.toISOString() },
      })
      .then((response) => response.data);
  }
}

export default TransactionApi;
