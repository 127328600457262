import { Address } from '@/api/merchant-service/common-types';
import { myAxios } from '@/plugins/api';

export interface Site {
  name: string;
  displayName?: string;
  phoneNumber: string;
  address: Address;
  payByLinkTermsUrl?: string;
  shortCode?: string;
}

class SiteApi {
  private readonly merchantId: () => string;
  constructor(merchantId: () => string) {
    this.merchantId = merchantId;
  }

  public async createNewSite(request: Site): Promise<void> {
    return myAxios.post(`/merchants/${this.merchantId()}/sites`, { site: this.cleanRequest(request) });
  }

  public async getSiteDetails(siteId: string): Promise<Site> {
    return myAxios.get(`/merchants/${this.merchantId()}/sites/${siteId}`).then((response) => response.data.site);
  }

  public async updateSiteDetails(siteId: string, request: Site): Promise<void> {
    return myAxios.put(`/merchants/${this.merchantId()}/sites/${siteId}`, { site: this.cleanRequest(request) });
  }

  private cleanRequest(request: Site): Site {
    if (!request.displayName) {
      delete request.displayName;
    }
    if (!request.address.line2) {
      delete request.address.line2;
    }
    if (!request.address.stateOrProvince) {
      delete request.address.stateOrProvince;
    }
    if (!request.payByLinkTermsUrl) {
      delete request.payByLinkTermsUrl;
    }
    if (!request.shortCode) {
      delete request.shortCode;
    }
    request.address.postalCode = request.address.postalCode.toUpperCase();
    return request;
  }
}

export default SiteApi;
