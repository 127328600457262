<template>
  <v-container class="full-height d-flex flex-wrap align-start py-8">
    <!-- KYC statuses -->
    <v-col cols="12" sm="6" class="px-4 px-sm-6">
      <v-sheet class="rounded-lg bg-pink-1 px-4 py-6 d-flex flex-column gap-4 cursor-pointer">
        <v-row v-for="(status, key) in filteredKycDetails" :key="key" class="align-center px-6" @click="handleKYCStatusClick">
          <!-- Label column -->
          <v-col cols="auto" class="d-flex align-center">
            <span>{{ kycStatusKeyLabelMapping[key] }}</span>
          </v-col>
          <!-- Icon column -->
          <v-col cols="auto" class="d-flex align-center">
            <v-icon :color="status ? 'green' : 'amber'">
              {{ status ? 'mdi-check-circle' : 'mdi-dots-horizontal-circle' }}
            </v-icon>
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>

    <!-- Order devices -->
    <v-col cols="12" sm="6" class="px-4 px-sm-12 pt-12 pt-sm-2 d-flex flex-column justify-center">
      <h3 class="mb-4">Order your card machine</h3>
      <p class="mb-6">Let’s get you taking payments! Set up a store and order your card machines.</p>
      <v-btn color="primary" rounded class="text-none">Let’s go</v-btn>
    </v-col>
  </v-container>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useOnboardingStore } from '@/store/onboarding';

const onboardingStore = useOnboardingStore();

const kycStatusKeyLabelMapping: Record<string, string> = {
  businessDetails: 'Business details',
  decisionMakers: 'Decision makers',
  bankAccountDetails: 'Bank account',
  signServiceAgreement: 'Service agreement',
};

const filteredKycDetails = computed(() => {
  const kycDetails = onboardingStore.draftMerchant?.kycOnboardingDetails || {};
  return Object.fromEntries(Object.entries(kycDetails).filter(([key]) => key in kycStatusKeyLabelMapping));
});

const handleKYCStatusClick = async () => {
  try {
    const merchantWithLink = await onboardingStore.submitDraftMerchant();
    if (merchantWithLink.hostedOnboardingLink) {
      window.open(merchantWithLink.hostedOnboardingLink, '_self');
    }
  } catch (error) {
    console.error('Failed get new hostedOnboardingLink', error);
    // TODO: handle error
  }
};
</script>
