<template>
  <div class="d-flex flex-column ga-10 mb-4">
    <CompanyFeatures />

    <EditCompanySettlementWindow v-if="posthog?.isFeatureEnabled('enable-company-close-time-updating')" />

    <CompanyRates
      v-if="posthog?.isFeatureEnabled('enable-deal-rate-updating') && !!deal"
      :deal-rates="deal"
      :is-loading="isCompanyRatesLoading"
      @update="updateDealRates"
    />
  </div>
</template>

<script setup lang="ts">
import { AxiosError } from 'axios';
import { PostHog } from 'posthog-js';
import { ref, inject, onMounted } from 'vue';
import { DealRates, UpdateRates } from '@/api/merchant-service/merchant';
import { API } from '@/plugins/api';
import { useApplicationStore } from '@/store/application';
import { useMerchantsStore } from '@/store/merchants';

const merchantsStore = useMerchantsStore();
const applicationStore = useApplicationStore();

const api = inject<API>('api');
const posthog = inject<PostHog>('posthog');

const isCompanyRatesLoading = ref(false);
const deal = ref<DealRates | undefined>(undefined);

onMounted(async () => {
  deal.value = await api!.merchant.getDeal(merchantsStore.currentMerchant!.merchantId);
});

const updateDealRates = async (updateRates: UpdateRates) => {
  try {
    isCompanyRatesLoading.value = true;

    const merchantId = merchantsStore.currentMerchant!.merchantId;
    await api!.merchant.updateDealRates(merchantId!, updateRates);
    deal.value = await api!.merchant.getDeal(merchantsStore.currentMerchant!.merchantId);

    applicationStore.notifyUser({ message: 'Rates updated successfully', type: 'success' });
  } catch (error: unknown) {
    let message = 'An error occurred while updating rates';
    if (error instanceof AxiosError && error.response?.data?.error) {
      message += `: ${error.response?.data?.error}`;
    }
    applicationStore.notifyUser({ message, type: 'error' });
  } finally {
    isCompanyRatesLoading.value = false;
  }
};
</script>
