<template>
  <div v-if="authorizationStore.hasCompanyScoped(Permission.Site_CreateSite)" class="d-flex align-center mb-2">
    <v-btn-nd class="ms-auto" to="/new-site">New site</v-btn-nd>
  </div>

  <v-data-table
    :items-per-page="-1"
    :headers="headers"
    :items="items"
    :loading="loading"
    no-data-text="No sites found."
    item-value="pspReference"
    :sort-by="[]"
  >
    <template #top></template>

    <template #item.name="{ item }">
      <div class="d-flex align-center">
        <span class="text-sm font-weight-normal text-body">
          {{ item.siteName }}
        </span>
      </div>
    </template>

    <template #header.name="{ column }">
      <div class="d-flex align-center">
        <span class="text-sm font-weight-normal text-body">
          {{ column.title }}
        </span>
      </div>
    </template>

    <template #header.settlementWindow="{ column }">
      <div class="d-flex align-center">
        <span class="text-sm font-weight-normal text-body">
          <v-tooltip activator="parent" location="top">{{ t('sites.settlementWindowToolTip') }}</v-tooltip>
          {{ column.title }}
          <v-icon color="secondary"> mdi-information-variant-circle </v-icon>
        </span>
      </div>
    </template>

    <template #item.settlementWindow="{ item }">
      <div class="d-flex align-center">
        <span class="text-sm font-weight-normal text-body">
          {{ formatSalesWindowCloseTime(item.salesDayClosingTime) }}
          ({{ formatTimeZone(item.timeZone) }})
          <v-tooltip activator="parent" location="right">{{ item.timeZone ? item.timeZone.split('/')[1] : '' }} time</v-tooltip>
        </span>
      </div>
    </template>

    <template #item.actions="{ item }">
      <div class="d-flex justify-end">
        <v-menu>
          <template #activator="{ props }">
            <v-btn variant="plain" icon v-bind="props">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item :to="`/sites/${item.siteId}`">
              <v-list-item-title> <v-icon class="mr-2">mdi-pencil</v-icon> Edit site</v-list-item-title>
            </v-list-item>
            <v-list-item :to="`/devices?selectedSite=${item.siteId}`">
              <v-list-item-title> <v-icon class="mr-2">mdi-cellphone</v-icon> See devices</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="authorizationStore.isStaff" :to="`/sites/${item.siteId}/internal`">
              <v-list-item-title> <v-icon class="mr-2">mdi-cog</v-icon> Internal settings</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>

    <template #bottom></template>
  </v-data-table>
</template>

<script setup lang="ts">
import { captureException } from '@sentry/vue';
import { ListSites200ResponseDataItemsInner } from '@tableyeti/merchant-service/api.ts';
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useYetipayApi } from '@/composables/useYetipayApi';
import { Permission, useAuthorizationStore } from '@/store/authorization';

const authorizationStore = useAuthorizationStore();
const { t } = useI18n();
const { api, currentMerchantId } = useYetipayApi();

const loading = ref(false);
const items = ref<ListSites200ResponseDataItemsInner[]>([]);

onMounted(async () => {
  loading.value = true;
  try {
    items.value = await api.listSites(currentMerchantId, true).then((response) => response.data.data.items);
  } catch (error) {
    captureException(error);
    console.log(error);
  } finally {
    loading.value = false;
  }
});

const formatTimeZone = (timeZone?: string) => {
  try {
    const now = new Date();
    const formatter = new Intl.DateTimeFormat('en-GB', {
      timeZone,
      timeZoneName: 'short',
    });
    const parts = formatter.formatToParts(now);
    const timeZonePart = parts.find((part) => part.type === 'timeZoneName');

    if (timeZonePart) {
      return timeZonePart.value;
    } else {
      throw new Error('Could not find time zone name part');
    }
  } catch (error) {
    console.error(`Error formatting time zone "${timeZone}":`, error);
    return null;
  }
};

const formatSalesWindowCloseTime = (closingTime?: string) => {
  if (!closingTime) {
    return 'Midnight to midnight';
  }
  return `${closingTime}am to ${closingTime}am`;
};

const headers = [
  { title: 'Name', value: 'name', sortable: false },
  { title: 'Settlement window', value: 'settlementWindow', sortable: false },
  { title: '', value: 'actions', sortable: false },
];
</script>

<style scoped>
.v-table :deep(div.v-data-table__td-sort-select) {
  display: none;
}
</style>
