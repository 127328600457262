<template>
  <div class="text-white w-100" style="margin: 0 !important; padding: 0 !important">
    <a
      v-if="onboardingStore.draftMerchant"
      class="position-fixed top-0 right-0 mt-8 mr-8 text-decoration-underline cursor-pointer"
      style="z-index: 1000"
      @click="handleExitOnboardingClick"
    >
      Exit
    </a>
    <component :is="componentForStatus" />
  </div>
</template>

<script setup lang="ts">
import { DraftMerchantStatusEnum } from '@tableyeti/merchant-service';
import { computed, onBeforeMount } from 'vue';
import type { Component } from 'vue';
import OnboardingAuth from '@/components/Onboarding/OnboardingAuth.vue';
import OnboardingDetails from '@/components/Onboarding/OnboardingDetails.vue';
import OnboardingKYCStatus from '@/components/Onboarding/OnboardingKYCStatus.vue';
import { useOnboardingStore } from '@/store/onboarding';

const onboardingStore = useOnboardingStore();

onBeforeMount(async () => {
  await onboardingStore.initialize();
});

const componentForStatus = computed(() => {
  if (!onboardingStore.draftMerchant) return OnboardingAuth;

  const statusComponentMap: { [status in DraftMerchantStatusEnum]?: Component } = {
    [DraftMerchantStatusEnum.Created]: OnboardingDetails,
    [DraftMerchantStatusEnum.DetailsAdded]: OnboardingDetails,
    [DraftMerchantStatusEnum.DetailsSubmittedError]: OnboardingDetails,
    [DraftMerchantStatusEnum.DetailsSubmitted]: OnboardingKYCStatus,
  };

  return statusComponentMap[onboardingStore.draftMerchant.status] || OnboardingAuth;
});

const handleExitOnboardingClick = () => {
  onboardingStore.clearDraftMerchant();
};
</script>
