<template>
  <FormCarousel ref="carouselControls">
    <!-- Field 1: first name -->
    <FormCarouselItem name="firstName" :disable-next-if="v$.firstName.$invalid" @focus="firstNameFieldRef?.focus">
      <FormQuestionLabel>What's your first name?</FormQuestionLabel>
      <v-row class="mt-4">
        <FormTextInput
          ref="firstNameFieldRef"
          v-model="form.firstName"
          autofocus
          autocapitalize="words"
          :error-messages="validationErrors(v$.firstName.$errors)"
        />
      </v-row>
    </FormCarouselItem>

    <!-- Field 2: last name -->
    <FormCarouselItem name="lastName" :disable-next-if="v$.lastName.$invalid" @focus="lastNameFieldRef?.focus">
      <FormQuestionLabel>And what's your last name?</FormQuestionLabel>
      <v-row class="mt-4">
        <FormTextInput ref="lastNameFieldRef" v-model="form.lastName" autocapitalize="words" :error-messages="validationErrors(v$.lastName.$errors)" />
      </v-row>
    </FormCarouselItem>

    <!-- Field 3: phone number -->
    <FormCarouselItem name="phoneNumber" :disable-next-if="v$.phoneNumber.$invalid" @focus="phoneNumberFieldRef?.focus">
      <FormQuestionLabel>What's your mobile number?</FormQuestionLabel>
      <v-row class="mt-4">
        <v-col class="m-0 p-0" cols="5" sm="4" md="2" xl="1">
          <v-select v-model="phoneCountryCode" :items="phoneCountryCodes" item-title="text" persistent-hint required variant="underlined" />
        </v-col>
        <v-col cols="7" sm="8" md="10" xl="11">
          <FormTextInput
            ref="phoneNumberFieldRef"
            v-model="phoneNumber"
            type="tel"
            :prefix="phoneCountryCode"
            :error-messages="validationErrors(v$.phoneNumber.$errors)"
          />
        </v-col>
      </v-row>
    </FormCarouselItem>

    <!-- Field 4: country -->
    <FormCarouselItem name="country" :disable-next-if="v$.country.$invalid" @focus="countryFieldRef?.focus">
      <FormQuestionLabel>Where is your business based?</FormQuestionLabel>
      <v-row>
        <v-autocomplete
          ref="countryFieldRef"
          v-model="form.country"
          :items="tradedCountries"
          item-title="name"
          item-value="code"
          variant="underlined"
          placeholder="Country"
        />
      </v-row>
    </FormCarouselItem>

    <!-- Field 5: entity -->
    <FormCarouselItem name="entity" :disable-next-if="v$.entity.$invalid">
      <FormQuestionLabel>And what kind of business do you have?</FormQuestionLabel>
      <v-row class="mt-8">
        <v-col xs="12" sm="8" md="6" lg="4">
          <v-btn-toggle v-model="form.entity" mandatory class="d-flex flex-column fill-height ga-6">
            <v-btn value="company" class="text-start justify-start" height="50" variant="elevated" @click="goToNextSlide"> I have a limited company → </v-btn>
            <v-btn value="sole-trader" class="text-start justify-start" height="50" variant="elevated" @click="goToNextSlide"> I am a sole trader → </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
    </FormCarouselItem>

    <!-- Field 6: legal entity name -->
    <FormCarouselItem name="legalEntityName" :disable-next-if="v$.legalEntityName.$invalid" @focus="legalEntityNameFieldRef?.focus">
      <FormQuestionLabel>{{ form.entity === 'company' ? "What's your company's registered name?" : "What's your business's trading name?" }}</FormQuestionLabel>
      <v-row class="mt-4">
        <FormTextInput
          ref="legalEntityNameFieldRef"
          v-model="form.legalEntityName"
          autocapitalize="words"
          :error-messages="validationErrors(v$.legalEntityName.$errors)"
        />
      </v-row>
    </FormCarouselItem>

    <!-- Field 7: company registration number -->
    <FormCarouselItem
      name="registrationNumber"
      :disabled="form.entity === 'sole-trader'"
      :disable-next-if="v$.registrationNumber.$invalid"
      @focus="registrationNumberFieldRef?.focus"
    >
      <FormQuestionLabel>What's your registered company number?</FormQuestionLabel>
      <v-row class="mt-4">
        <FormTextInput
          ref="registrationNumberFieldRef"
          v-model="form.registrationNumber"
          autocapitalize="words"
          :error-messages="validationErrors(v$.registrationNumber.$errors)"
        />
      </v-row>
    </FormCarouselItem>

    <!-- Field 8: industry code -->
    <FormCarouselItem
      name="industryCode"
      :disable-next-if="v$.industryCode.$invalid"
      :next-loading="submitting"
      @focus="industryCodeFieldRef?.focus"
      @next="submit"
    >
      <FormQuestionLabel>What's your company's line of business?</FormQuestionLabel>
      <v-row class="mt-4">
        <v-autocomplete
          ref="industryCodeFieldRef"
          v-model="form.industryCode"
          class="pb-5"
          :items="industryCodes"
          item-title="name"
          item-value="value"
          variant="underlined"
          label="Industry"
        />
      </v-row>
    </FormCarouselItem>
  </FormCarousel>
</template>

<script setup lang="ts">
import { DraftMerchantEntityEnum, UpdateDraftMerchantDetails } from '@tableyeti/merchant-service';
import useVuelidate, { ErrorObject } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { computed, onMounted, reactive, ref } from 'vue';
import { VAutocomplete } from 'vuetify/components';
import { FormCarousel } from '../Form';
import { Industry } from '@/api/merchant-service/merchant';
import FormTextInput from '@/components/Form/FormTextInput.vue';
import { OnboardingError, useOnboardingStore } from '@/store/onboarding';
import { phoneCountryCodes, tradedCountries } from '@/utils/config';

const carouselControls = ref<InstanceType<typeof FormCarousel>>();

const goToNextSlide = () => {
  carouselControls.value?.next();
};
const onboardingStore = useOnboardingStore();

const firstNameFieldRef = ref<typeof FormTextInput>();
const lastNameFieldRef = ref<typeof FormTextInput>();
const phoneNumberFieldRef = ref<typeof FormTextInput>();
const countryFieldRef = ref<typeof VAutocomplete>();
const legalEntityNameFieldRef = ref<typeof FormTextInput>();
const registrationNumberFieldRef = ref<typeof FormTextInput>();
const industryCodeFieldRef = ref<typeof VAutocomplete>();

const industryCodes = ref<Industry[]>([]);

onMounted(async () => {
  industryCodes.value = await onboardingStore.getIndustries();
});
const phoneCountryCode = ref('+44');
const phoneNumber = ref('');

const form = reactive({
  firstName: '',
  lastName: '',
  get phoneNumber() {
    return phoneCountryCode.value + phoneNumber.value;
  },
  country: null as null | string,
  entity: null as null | DraftMerchantEntityEnum,
  legalEntityName: '',
  registrationNumber: '',
  industryCode: null as null | string,
});

const rules = computed(() => ({
  firstName: {
    required: helpers.withMessage('Required', required),
  },
  lastName: {
    required: helpers.withMessage('Required', required),
  },
  phoneNumber: {
    required: helpers.withMessage('Required', required),
    phoneNumberValidator: helpers.withMessage('Please enter a valid phone number', () => parsePhoneNumber(phoneCountryCode.value + phoneNumber.value).valid),
  },
  country: { required },
  entity: { required },
  legalEntityName: { required: helpers.withMessage('Required', required) },
  registrationNumber: form.entity === 'sole-trader' ? {} : { required: helpers.withMessage('Required', required) },
  industryCode: { required },
}));

// TODO: don't using auto dirty, touch on invalid next button click / enter press
const v$ = useVuelidate(rules, form);

const submitting = ref<boolean>(false);

const submit = async () => {
  carouselControls.value?.clearError();
  v$.value.$touch();
  if (v$.value.$invalid) {
    carouselControls.value?.setError(v$.value.$errors[0]?.$message.toString() || 'Please ensure all fields are filled correctly');
    return;
  }

  const merchantRequest = { ...form, email: onboardingStore.draftMerchant!.email } as UpdateDraftMerchantDetails;

  if (merchantRequest.entity === DraftMerchantEntityEnum.SoleTrader) {
    delete merchantRequest.registrationNumber;
  }

  try {
    submitting.value = true;
    await onboardingStore.updateDraftMerchant(merchantRequest);
    const merchantWithLink = await onboardingStore.submitDraftMerchant();
    if (merchantWithLink.hostedOnboardingLink) {
      window.open(merchantWithLink.hostedOnboardingLink, '_self');
    }
  } catch (error) {
    if (error instanceof OnboardingError) {
      carouselControls.value?.setError(error.message, error.field ?? 'firstName');
    } else {
      carouselControls.value?.setError('Something went wrong', 'firstName');
    }
  } finally {
    submitting.value = false;
  }
};

const validationErrors = (errors: ErrorObject[]) => errors.map((e) => e.$message.toString());
</script>
