import * as Sentry from '@sentry/vue';
import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserSession,
  CognitoAccessToken,
  CognitoIdToken,
} from 'amazon-cognito-identity-js';
import { defineStore } from 'pinia';
import app from '@/main';
import { useApplicationStore } from '@/store/application';
import { useAuthorizationStore } from '@/store/authorization';
interface ConfirmUserInputDetails {
  email: string;
  confirmationCode: string;
}

const userPool = new CognitoUserPool({
  UserPoolId: import.meta.env.VITE_USER_POOL_ID,
  ClientId: import.meta.env.VITE_USER_POOL_WEB_CLIENT_ID,
});

export enum LOADING_STATES {
  IDLE = 'IDLE',
  NOT_LOADED = 'NOT_LOADED',
  ERROR = 'ERROR',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
}

interface SignInInputDetails {
  email: string;
  password: string;
}

interface SignUpInputDetails {
  email: string;
  password: string;
  phoneNumber: string;
}

interface AuthenticationState {
  initialiseState: LOADING_STATES;
  user: {
    isAuthenticated: boolean;
    isConfigured: boolean;
    id: string;
    email: string;
    groups: string[];
    idToken: string;
  };
  signIn: {
    status: LOADING_STATES;
    userRequiresConfirmation: boolean | null;
    forceNewPassword: boolean | null;
    errorMessage: string;
  };
  signUp: {
    status: LOADING_STATES;
    userRequiresConfirmation: boolean | null;
    errorMessage: string;
  };
  confirmUser: {
    status: LOADING_STATES;
    errorMessage: string;
  };
  forgotPassword: {
    status: LOADING_STATES;
    errorMessage: string;
  };
  forceNewPassword: {
    status: LOADING_STATES;
    temporaryUserContext: CognitoUser | null;
    errorMessage: string;
  };
}

const state = (): AuthenticationState => ({
  initialiseState: LOADING_STATES.NOT_LOADED,
  user: {
    isAuthenticated: false,
    isConfigured: false,
    id: '',
    email: '',
    groups: [],
    idToken: '',
  },
  signIn: {
    status: LOADING_STATES.IDLE,
    userRequiresConfirmation: null,
    forceNewPassword: null,
    errorMessage: '',
  },
  signUp: {
    status: LOADING_STATES.IDLE,
    userRequiresConfirmation: null,
    errorMessage: '',
  },
  confirmUser: {
    status: LOADING_STATES.IDLE,
    errorMessage: '',
  },
  forgotPassword: {
    status: LOADING_STATES.IDLE,
    errorMessage: '',
  },
  forceNewPassword: {
    status: LOADING_STATES.IDLE,
    temporaryUserContext: null,
    errorMessage: '',
  },
});

const getters = {
  getCurrentUser(this: AuthenticationState) {
    return userPool.getCurrentUser();
  },
};

const getSession = async () => {
  const user = userPool.getCurrentUser() as CognitoUser;
  return new Promise<CognitoUserSession>((resolve, reject) => {
    if (!user) {
      reject('No user found');
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      user.getSession((error: any, session: CognitoUserSession) => {
        if (error) {
          reject(error);
        } else {
          resolve(session);
        }
      });
    }
  });
};

const setContext = ($context: AuthenticationState, accessToken: CognitoAccessToken, idToken: CognitoIdToken) => {
  $context.user.isAuthenticated = true;
  $context.user.id = idToken.payload['custom:userId'];
  $context.user.email = idToken.payload.email;
  $context.user.groups = accessToken.payload['cognito:groups'];
  $context.user.idToken = idToken.getJwtToken();
  const userIdClaim = idToken.payload['custom:userId'];
  $context.user.isConfigured = !!userIdClaim && userIdClaim !== '' && !!$context.user.groups && $context.user.groups.length > 0;
  app.config.globalProperties.$posthog.identify($context.user.email, {
    email: $context.user.email,
    id: idToken.payload['custom:userId'],
    isStaff: $context.user.groups.includes('Staff'),
  });
  Sentry.getCurrentScope()?.setUser({ id: idToken.payload['custom:userId'] });
  useAuthorizationStore().isStaff = $context.user.groups.includes('Staff');
};

const actions = {
  resetErrorMessages(this: AuthenticationState) {
    this.signIn.errorMessage = '';
    this.signUp.errorMessage = '';
    this.confirmUser.errorMessage = '';
    this.forgotPassword.errorMessage = '';
    this.forceNewPassword.errorMessage = '';
  },
  async initialise(this: AuthenticationState) {
    const $context = this;
    try {
      const session = await getSession();
      setContext($context, session.getAccessToken(), session.getIdToken());
      this.initialiseState = LOADING_STATES.LOADED;
    } catch (error) {
      console.warn(error);
    }
  },
  async refreshToken(this: AuthenticationState) {
    const $context = this;
    const cognitoUser = new CognitoUser({ Username: $context.user.email, Pool: userPool });
    try {
      const refresh_token = (await getSession()).getRefreshToken();
      return new Promise<void>((resolve, reject) => {
        cognitoUser.refreshSession(refresh_token, (err, session) => {
          if (err) {
            reject(err);
          } else {
            setContext($context, session.getAccessToken(), session.getIdToken());
            resolve();
          }
        });
      });
    } catch (error) {
      console.warn(error);
    }
  },
  async initSignIn(this: AuthenticationState, { email, password }: SignInInputDetails) {
    const $context = this;
    this.signIn.status = LOADING_STATES.LOADING;
    return new Promise((resolve) => {
      const authenticationDetails = new AuthenticationDetails({ Username: email, Password: password });

      const cognitoUser = new CognitoUser({ Username: email, Pool: userPool });
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          setContext($context, result.getAccessToken(), result.getIdToken());
          $context.signIn.status = LOADING_STATES.LOADED;
          resolve(true);
        },
        onFailure: (error) => {
          switch (error.code) {
            case 'UserNotConfirmedException':
              $context.signIn.userRequiresConfirmation = true;
              break;

            case 'UserNotFoundException':
              $context.signIn.errorMessage = 'Incorrect email or password';
              break;

            case 'NotAuthorizedException':
              $context.signIn.errorMessage = 'Incorrect email or password';
              break;

            default:
              $context.signIn.errorMessage = 'Oops, something went wrong. Please try again later';
          }
          $context.user.isAuthenticated = false;
          $context.user.isConfigured = false;
          $context.signIn.status = LOADING_STATES.LOADED;
          resolve(false);
        },
        newPasswordRequired: () => {
          $context.forceNewPassword.temporaryUserContext = cognitoUser;
          $context.signIn.status = LOADING_STATES.IDLE;
          $context.signIn.forceNewPassword = true;
          $context.signIn.errorMessage = '';
          resolve(false);
        },
      });
    });
  },
  async initForceNewPassword(this: AuthenticationState, { newPassword }: { newPassword: string }) {
    const $context = this;
    this.forceNewPassword.status = LOADING_STATES.LOADING;
    return new Promise((resolve) => {
      $context.forceNewPassword.temporaryUserContext?.completeNewPasswordChallenge(
        newPassword,
        {},
        {
          onSuccess: () => {
            $context.forceNewPassword.status = LOADING_STATES.LOADED;
            resolve(true);
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onFailure: (error: any) => {
            if (error) {
              const errorCode = error.code;
              switch (errorCode) {
                case 'NotAuthorizedException':
                  $context.forgotPassword.errorMessage = 'We were unable to verify your new password, please try again later.';
                  break;
                default:
                  $context.forceNewPassword.errorMessage = 'Oops, something went wrong. Please try again later';
              }
            }
            $context.forceNewPassword.status = LOADING_STATES.IDLE;
            resolve(false);
          },
        },
      );
    });
  },
  async initSignUp(this: AuthenticationState, { email, password, phoneNumber }: SignUpInputDetails) {
    const $context = this;
    const dataEmail = { Name: 'email', Value: email };
    const dataPhoneNumber = { Name: 'phone_number', Value: phoneNumber };
    const attributeList = [new CognitoUserAttribute(dataEmail), new CognitoUserAttribute(dataPhoneNumber)];

    return new Promise((resolve) => {
      userPool.signUp(email, password, attributeList, [], (error, result) => {
        if (error) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const errorCode = (error as any).code;
          console.log(errorCode);
          switch (errorCode) {
            case 'UsernameExistsException':
              $context.signUp.errorMessage = 'A user already exists with that email address.';
              break;
            case 'InvalidParameterException':
              $context.signUp.errorMessage = 'Invalid email, phone or password';
              break;
            default:
              $context.signIn.errorMessage = 'Oops, something went wrong. Please try again later';
          }
          resolve(false);
          return;
        }

        if (result?.userConfirmed == false) {
          $context.signUp.userRequiresConfirmation = true;
        }
        $context.signUp.status = LOADING_STATES.LOADED;
        resolve(true);
      });
    });
  },
  async initConfirmUser(this: AuthenticationState, { email, confirmationCode }: ConfirmUserInputDetails) {
    const $context = this;
    const cognitoUser = new CognitoUser({ Username: email, Pool: userPool });
    $context.confirmUser.status = LOADING_STATES.LOADING;

    return new Promise((resolve) => {
      cognitoUser.confirmRegistration(confirmationCode, true, (error) => {
        if (error) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const errorCode = (error as any).code;
          switch (errorCode) {
            case 'CodeMismatchException':
              $context.confirmUser.errorMessage = 'Invalid verification code, please try again.';
              break;

            case 'ExpiredCodeException':
              $context.confirmUser.errorMessage = 'Verification code has expired, please request a new one.';
              break;

            default:
              $context.signIn.errorMessage = 'Oops, something went wrong. Please try again later';
          }
          resolve(false);
          $context.confirmUser.status = LOADING_STATES.LOADED;
          return;
        }
        $context.confirmUser.status = LOADING_STATES.LOADED;
        resolve(true);
      });
    });
  },
  async initResendConfirmationCode(this: AuthenticationState, email: string) {
    const $context = this;
    const cognitoUser = new CognitoUser({ Username: email, Pool: userPool });
    $context.confirmUser.status = LOADING_STATES.LOADING;
    return new Promise((resolve) => {
      cognitoUser.resendConfirmationCode((error) => {
        if (error) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const errorCode = (error as any).code;

          switch (errorCode) {
            case 'LimitExceededException':
              $context.confirmUser.errorMessage = 'You have exceeded the limit for sending confirmation codes, please try again later.';
              break;
            case 'SerializationException':
              $context.confirmUser.errorMessage = 'Oops, something went wrong. Please try again later';
              break;

            default:
              $context.confirmUser.errorMessage = 'Oops, something went wrong. Please try again later';
          }
          resolve(false);
          return;
        }
        $context.confirmUser.status = LOADING_STATES.LOADED;
        resolve(true);
      });
    });
  },
  async initForgotPassword(this: AuthenticationState, email: string) {
    const $context = this;
    const cognitoUser = new CognitoUser({ Username: email, Pool: userPool });
    $context.forgotPassword.status = LOADING_STATES.LOADING;

    return new Promise((resolve) => {
      cognitoUser.forgotPassword({
        onSuccess: () => {
          $context.forgotPassword.status = LOADING_STATES.LOADED;
          resolve(true);
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onFailure: (error: any) => {
          if (error) {
            const errorCode = error.code;
            switch (errorCode) {
              case 'LimitExceededException':
                $context.forgotPassword.errorMessage = 'access.forgot.limitExceededException';
                break;
              case 'UserNotFoundException':
                $context.forgotPassword.errorMessage = 'access.forgot.userNotFoundException';
                break;
              case 'NotAuthorizedException':
                $context.forgotPassword.errorMessage = 'access.forgot.notAuthorizedException';
                break;
              default:
                $context.forgotPassword.errorMessage = 'access.forgot.genericException';
            }
          }
          $context.forgotPassword.status = LOADING_STATES.LOADED;
          resolve(false);
        },
      });
    });
  },
  async initConfirmNewPassword(this: AuthenticationState, email: string, confirmationCode: string, newPassword: string) {
    const $context = this;
    const cognitoUser = new CognitoUser({ Username: email, Pool: userPool });
    $context.forgotPassword.status = LOADING_STATES.LOADING;

    return new Promise((resolve) => {
      cognitoUser.confirmPassword(confirmationCode, newPassword, {
        onSuccess: () => {
          $context.forgotPassword.status = LOADING_STATES.LOADED;
          resolve(true);
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onFailure: (error: any) => {
          if (error) {
            const errorCode = error.code;
            switch (errorCode) {
              case 'CodeMismatchException':
                $context.forgotPassword.errorMessage = 'Invalid password reset code, please try again.';
                break;
              case 'ExpiredCodeException':
                $context.forgotPassword.errorMessage = 'Verification code has expired, please request a new one.';
                break;
              default:
                $context.forgotPassword.errorMessage = 'Oops, something went wrong. Please try again later';
            }
          }
          $context.forgotPassword.status = LOADING_STATES.LOADED;
          resolve(false);
        },
      });
    });
  },
  async signOut(this: AuthenticationState) {
    const $context = this;
    const applicationStore = useApplicationStore();
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
    }
    $context.user.isAuthenticated = false;
    $context.user.isConfigured = false;
    $context.user.email = '';
    $context.user.groups = [];
    $context.user.idToken = '';
    app.config.globalProperties.$posthog.reset();
    Sentry.getCurrentScope()?.setUser(null);
    applicationStore.reset();
    applicationStore.notifyUser({ message: 'You have been signed out.' });
  },
};

export const useAuthenticationStore = defineStore('authentication', {
  state,
  getters,
  actions,
});
