<template>
  <v-alert type="info" variant="outlined">
    You are editing the internal settings for site "<kbd>{{ siteName }}</kbd
    >".
  </v-alert>

  <v-progress-linear v-if="!siteName" indeterminate class="my-5" color="primary" />

  <div v-else>
    <v-tabs v-model="tab" align-tabs="center" color="deep-purple-accent-4" class="mt-3">
      <v-tab :value="Tab.Payouts">Payouts</v-tab>
      <v-tab :value="Tab.PaymentMethods">Payment methods</v-tab>
      <v-tab :value="Tab.Tips">Tips</v-tab>
      <v-tab :value="Tab.Integrations">Integrations</v-tab>
    </v-tabs>

    <v-tabs-window v-model="tab">
      <v-tabs-window-item :value="Tab.Payouts">
        <site-payout-config :site-id="siteId" :settings="payoutSettings" :transfer-instruments="transferInstruments" />
      </v-tabs-window-item>

      <v-tabs-window-item :value="Tab.PaymentMethods">
        <site-payment-methods-config :site-id="siteId" :payment-methods="paymentMethods" />
      </v-tabs-window-item>

      <v-tabs-window-item :value="Tab.Tips">
        <site-tips-config :settings="internalSettings?.tips" :loading="updateLoading" @update-internal-settings="updateInternalSettings" />
      </v-tabs-window-item>

      <v-tabs-window-item :value="Tab.Integrations">
        <site-integrations-config
          :push="internalSettings?.connectPush"
          :pull="internalSettings?.connectPull"
          :loading="updateLoading"
          @update-internal-settings="updateInternalSettings"
        />
      </v-tabs-window-item>
    </v-tabs-window>
  </div>
</template>

<script setup lang="ts">
import {
  BalanceAccountPayoutConfig,
  GetSiteSettings200ResponsePaymentMethodsInner,
  GetSiteSettings200ResponseTransferInstrumentsInner,
  SiteInternalSettings,
  SiteInternalSettingsConnectPull,
  SiteInternalSettingsConnectPush,
  SiteInternalSettingsTips,
} from '@tableyeti/merchant-service';
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useYetipayApi } from '@/composables/useYetipayApi';
import { useApplicationStore } from '@/store/application';

const { api, currentMerchantId } = useYetipayApi();
const applicationStore = useApplicationStore();
const siteId = useRoute().params.siteId as string;

enum Tab {
  Payouts = 'payouts',
  PaymentMethods = 'paymentMethods',
  Tips = 'tips',
  Integrations = 'integrations',
}
const tab = ref(Tab.Payouts);

const siteName = ref<string | undefined>(undefined);
const paymentMethods = ref<({ updateLoading?: boolean } & GetSiteSettings200ResponsePaymentMethodsInner)[] | undefined>(undefined);
const payoutSettings = ref<BalanceAccountPayoutConfig[] | undefined>(undefined);
const transferInstruments = ref<GetSiteSettings200ResponseTransferInstrumentsInner[] | undefined>([]);
const internalSettings = ref<SiteInternalSettings | undefined>(undefined);
onMounted(async () => {
  const internalSiteSettings = await api.getSiteSettings(currentMerchantId, siteId).then((res) => res.data);
  siteName.value = internalSiteSettings.name;
  paymentMethods.value = internalSiteSettings.paymentMethods;
  payoutSettings.value = internalSiteSettings.payoutSettings;
  transferInstruments.value = internalSiteSettings.transferInstruments;
  internalSettings.value = internalSiteSettings.internalSettings;
});

const updateLoading = ref(false);

const updateInternalSettings = async (
  key: keyof SiteInternalSettings,
  value?: SiteInternalSettingsTips | SiteInternalSettingsConnectPush | SiteInternalSettingsConnectPull,
) => {
  const newInternalSettings: SiteInternalSettings = { ...internalSettings.value, [key]: value };

  updateLoading.value = true;
  try {
    await api.updateSiteSettings(currentMerchantId, siteId, newInternalSettings);
    internalSettings.value = newInternalSettings;
    applicationStore.notifyUser({ message: 'Internal settings for site saved' });
  } catch (error) {
    console.error('Failed to save internal settings for site', error);
    applicationStore.notifyUser({ message: 'Failed to save internal settings for site', type: 'error' });
  } finally {
    updateLoading.value = false;
  }
};
</script>
