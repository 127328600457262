<template>
  <img v-if="img" class="payment-variant-icon" :src="img" alt="Payment method" />
  <span v-else>{{ model }}</span>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import icAmex from '@/assets/img/ic_amex.png';
import icBcmc from '@/assets/img/ic_bcmc.png';
import icCup from '@/assets/img/ic_cup.png';
import icDiscover from '@/assets/img/ic_discover.png';
import icGiro from '@/assets/img/ic_giro.png';
import icMaestro from '@/assets/img/ic_maestro.png';
import icMastercard from '@/assets/img/ic_mc.png';
import icVisa from '@/assets/img/ic_visa.png';

const model = defineModel({ type: String, required: true });
const img = computed(() => {
  switch (model.value) {
    case 'amex':
      return icAmex;
    case 'mc':
      return icMastercard;
    case 'visa':
      return icVisa;
    case 'maestro':
      return icMaestro;
    case 'bcmc':
      return icBcmc;
    case 'cup':
      return icCup;
    case 'discover':
      return icDiscover;
    case 'girocard':
      return icGiro;
    default:
      return null;
  }
});
</script>

<style scoped>
img.payment-variant-icon {
  height: 25px;
  vertical-align: middle;
}
</style>
