<template>
  <v-carousel-item v-if="!disabled" transition="carousel-form-slide" reverse-transition="carousel-form-slide-reverse">
    <v-container
      class="d-flex fill-height form-item-container align-start align-sm-center"
      style="margin-top: 0 !important; margin-bottom: 0 !important"
      @keyup.enter="handleNextClick"
    >
      <v-col class="px-10 px-sm-14 py-4 py-sm-0">
        <slot></slot>
        <FormButtons
          :disabled="disableNextIf"
          :next-label="item?.isLast ? 'Go' : 'Next'"
          :next-loading="nextLoading"
          enter-submit-enabled
          :previous-label="!item?.isFirst ? 'Back' : undefined"
          @next-click="handleNextClick"
          @previous-click="carouselContext?.goToItem(previousSlideIndex)"
        />
      </v-col>
    </v-container>
  </v-carousel-item>
</template>

<script setup lang="ts">
import { v4 as uuidv4 } from 'uuid';
import { computed, inject, onMounted, watch } from 'vue';
import { CarouselContext } from '@/components/Form/FormCarousel.vue';

const carouselContext = inject<CarouselContext>('carouselContext');

const itemId = uuidv4();

const { name, disabled, disableNextIf, nextLoading } = defineProps({
  name: {
    type: String,
    required: false,
    default: undefined,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  disableNextIf: {
    type: Boolean,
    required: false,
    default: false,
  },
  nextLoading: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const item = computed(() => carouselContext?.items.find((item) => item.id === itemId));

watch(
  () => disabled,
  () => {
    carouselContext?.updateItem({ id: itemId, name, disabled, onFocus: () => emit('focus') });
  },
  { immediate: true },
);

const nextSlideIndex = computed(() => (item.value?.index != null ? item.value.index + 1 : 0));
const previousSlideIndex = computed(() => (item.value?.index != null ? item.value.index - 1 : 0));

const emit = defineEmits(['focus', 'next']);

const handleNextClick = () => {
  if (!disableNextIf) {
    carouselContext?.goToItem(nextSlideIndex.value);
    emit('next');
  }
};

onMounted(() => {
  carouselContext?.registerItem({ id: itemId, name, disabled, onFocus: () => emit('focus') });
});
</script>

<style scoped>
.carousel-form-slide-enter-active {
  transition:
    transform 1s cubic-bezier(0.2, 0.8, 0.2, 1),
    opacity 1.2s cubic-bezier(0.2, 0.8, 0.2, 1);
  transition-delay: 0.1s;
  opacity: 1;
  position: absolute;
  width: 100%;
}

.carousel-form-slide-enter-from {
  transform: translateY(400px);
  opacity: 0;
}

.carousel-form-slide-leave-active {
  transition:
    transform 0.6s cubic-bezier(0.25, 0.6, 0.5, 1),
    opacity 0.6s;
  opacity: 1;
  position: absolute;
  width: 100%;
}

.carousel-form-slide-leave-to {
  transform: translateY(-500px);
}

.carousel-form-slide-reverse-enter-active {
  transition:
    transform 0.4s cubic-bezier(0.2, 0.8, 0.2, 1),
    opacity 0.5s;
  transition-delay: 0.2s;
  opacity: 1;
  position: absolute;
  width: 100%;
}

.carousel-form-slide-reverse-enter-from {
  transform: translateY(-500px);
}

.carousel-form-slide-reverse-leave-active {
  transition:
    transform 0.8s cubic-bezier(0.1, 0.3, 0.4, 1),
    opacity 0.5s;
  opacity: 0;
  position: absolute;
  width: 100%;
}

.carousel-form-slide-reverse-leave-to {
  transform: translateY(400px);
  opacity: 0;
}
</style>
