<template>
  <v-alert title="Trading hours" icon="mdi-clock">
    <p id="trading-hours-copy" class="d-flex flex-column flex-md-row ga-6 ga-md-0">
      {{ merchantsStore.currentMerchant?.legalEntityName }}'s trading hours are set to
      <v-select v-model="selectedCloseTime" density="compact" :items="closeTimes" class="mx-2" hide-details @update:model-value="openConfirmModal"></v-select>
      in the company's timezone.
    </p>
  </v-alert>

  <v-dialog v-model="confirmDialog" max-width="500px">
    <v-card>
      <template #title>
        <h3 class="text-error">Change trading hours</h3>
      </template>
      <v-card-text>Changing the company's trading hours will affect which transactions are included in their daily payouts.</v-card-text>
      <v-card-text>
        Would you like this company's daily payouts to be made up of transactions between
        <p class="font-weight-bold d-inline">{{ formatCloseTime(selectedCloseTime) }}</p>
        each day?
      </v-card-text>
      <v-card-text class="font-weight-bold">This change will affect all sites belonging to this company.</v-card-text>
      <template #actions>
        <v-btn-nd text="Cancel" class="ms-4 my-4" @click="cancelChange" />
        <v-spacer />
        <v-btn color="error" class="bg-error on-error me-4 my-4" text="Confirm" :loading="loading" @click="confirmChange" />
      </template>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useYetipayApi } from '@/composables/useYetipayApi';
import { useApplicationStore } from '@/store/application';
import { useMerchantsStore } from '@/store/merchants';

const { api, currentMerchantId } = useYetipayApi();
const merchantsStore = useMerchantsStore();
const applicationStore = useApplicationStore();

const currentCompanyCloseTime = merchantsStore.currentMerchant?.settings?.salesDayClosingTime ?? '00:00';

const formatCloseTime = (time: string) => `${time}am - ${time}am`;
const closeTimes = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00'].map((time) => ({ title: formatCloseTime(time), value: time }));

const selectedCloseTime = ref(currentCompanyCloseTime);
const previousCloseTime = ref(currentCompanyCloseTime);
const confirmDialog = ref(false);
const loading = ref(false);

const openConfirmModal = () => {
  confirmDialog.value = true;
};

const confirmChange = async () => {
  loading.value = true;
  try {
    await api.updateMerchantSettings(currentMerchantId, { salesDayClosingTime: selectedCloseTime.value });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    previousCloseTime.value = selectedCloseTime.value;
    applicationStore.notifyUser({ message: 'Trading hours successfully updated', type: 'success' });
  } catch (error) {
    console.error(error);
    applicationStore.notifyUser({ message: 'Unable to update trading hours', type: 'error' });
  } finally {
    loading.value = false;
    confirmDialog.value = false;
  }
};

const cancelChange = () => {
  selectedCloseTime.value = previousCloseTime.value;
  confirmDialog.value = false;
};
</script>
<style scoped>
p#trading-hours-copy {
  .v-input {
    margin-top: -9px;
    max-width: 230px;
  }
}
</style>
