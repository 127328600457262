import VueDatePicker from '@vuepic/vue-datepicker';
import type { App } from 'vue';
import api from '@/plugins/api';
import cookies from '@/plugins/cookie-consent';
import { i18n } from '@/plugins/i18n';
import intercom from '@/plugins/intercom';
import posthogPlugin from '@/plugins/posthog';
import vuetify from '@/plugins/vuetify';
import pinia from '@/store';
import '@vuepic/vue-datepicker/dist/main.css';

export function registerPlugins(app: App) {
  app.component('VueDatePicker', VueDatePicker);

  app.use(vuetify);
  app.use(i18n);
  app.use(pinia);
  app.use(cookies);
  app.use(posthogPlugin);
  app.use(api);
  app.use(intercom);
}
