import { onMounted, ref, watch } from 'vue';
import { TransactionsParams, useTransactionsStore } from '@/store/transactions.ts';

export function useTransactionParams() {
  const { transactionsParams } = useTransactionsStore();

  const paramsCount = ref<number>(0);
  const count = () => {
    paramsCount.value = Object.values(transactionsParams).reduce((acc: number, param) => {
      if (param === undefined || param === '') return acc;
      return (acc + (Array.isArray(param) ? param.length : 1)) as number;
    }, 0) as number;

    if (transactionsParams.dates?.length) {
      paramsCount.value--;
    }
    if (transactionsParams.siteId) {
      paramsCount.value--;
    }
  };
  onMounted(count);
  watch(() => transactionsParams, count, { deep: true });

  const clearFilter = (key: keyof TransactionsParams, value?: string) => {
    const param = transactionsParams[key];

    if (value && Array.isArray(param) && param.length) {
      // @ts-expect-error: due to TS not getting its actually an array
      transactionsParams[key] = param.filter((val) => val !== value);
    } else {
      transactionsParams[key] = undefined;
    }
  };

  const clearAllFilters = () =>
    Object.keys(transactionsParams)
      .filter((param) => param !== 'siteId')
      .forEach((key) => clearFilter(key as keyof TransactionsParams));

  return { paramsCount, clearAllFilters, clearFilter };
}
