<template>
  <div class="d-flex flex-wrap ga-2 align-center">
    <transition name="implode">
      <v-chip
        v-if="transactionsParams.dates"
        closable
        :text="formatDateFilterText"
        color="primary"
        :size="chipSize"
        inline
        @click:close="clearFilter('dates')"
      />
    </transition>
    <transition-group name="implode">
      <v-chip
        v-for="status in transactionsParams.statuses"
        :key="status"
        closable
        :text="formatTextWithDashes(status)"
        color="primary"
        inline
        :size="chipSize"
        @click:close="clearFilter('statuses', status)"
      />
    </transition-group>
    <transition name="implode">
      <v-chip
        v-if="transactionsParams.type"
        closable
        :text="transactionsParams.type"
        color="primary"
        inline
        :size="chipSize"
        @click:close="clearFilter('type')"
      />
    </transition>
    <transition name="implode">
      <v-chip
        v-if="transactionsParams.cardSummary"
        closable
        :text="`${transactionsParams.cardSummary}`"
        color="primary"
        inline
        :size="chipSize"
        @click:close="clearFilter('cardSummary')"
      />
    </transition>
    <transition name="implode">
      <v-chip
        v-if="transactionsParams.pspReference"
        closable
        :text="transactionsParams.pspReference"
        color="primary"
        inline
        :size="chipSize"
        @click:close="clearFilter('pspReference')"
      />
    </transition>
    <transition-group name="implode">
      <v-chip
        v-for="paymentMethod in transactionsParams.paymentMethods"
        :key="paymentMethod"
        :text="getPaymentMethodPublicName(paymentMethod)"
        color="primary"
        inline
        :size="chipSize"
        closable
        @click:close="clearFilter('paymentMethods', paymentMethod)"
      />
    </transition-group>
    <transition name="implode">
      <v-chip v-if="paramsCount > 1" text="Clear all" closable inline :size="chipSize" @click="clearAllFilters()" @click:close="clearAllFilters()" />
    </transition>
  </div>
</template>

<script setup lang="ts">
import { format, isSameDay, isToday } from 'date-fns';
import { computed } from 'vue';
import { useDisplay } from 'vuetify';
import { useTransactionParams } from '@/composables/useTransactionParams.ts';
import { useMerchantsStore } from '@/store/merchants';
import { isAfterMidnightButBeforeClosingTime, useTransactionsStore } from '@/store/transactions.ts';
import { formatTextWithDashes, getPaymentMethodPublicName } from '@/utils/format.ts';

const { xs } = useDisplay();
const { transactionsParams } = useTransactionsStore();
const { paramsCount, clearAllFilters, clearFilter } = useTransactionParams();

const formatDateFilterText = computed(() => {
  if (!transactionsParams?.dates) {
    return '';
  }

  const salesDayClosingTime = useMerchantsStore().currentMerchant?.settings?.salesDayClosingTime;

  if (salesDayClosingTime) {
    if (isAfterMidnightButBeforeClosingTime(salesDayClosingTime) && isToday(transactionsParams.dates[0])) {
      return `Since ${salesDayClosingTime}, yesterday`;
    }

    if (isToday(transactionsParams.dates[0])) {
      return `Today, since ${salesDayClosingTime}`;
    }
  }

  if (isSameDay(transactionsParams?.dates[0], transactionsParams?.dates[1])) {
    return isToday(transactionsParams.dates[0]) ? 'Today' : format(transactionsParams?.dates[0], 'yyyy.MM.dd');
  }

  return transactionsParams.dates?.map((date) => format(new Date(date), 'yyyy.MM.dd')).join(' - ');
});
const chipSize = computed(() => (xs.value ? 'small' : 'default'));
</script>

<style scoped>
.implode-leave-active {
  animation: growAndSquish 0.4s;
}

@keyframes growAndSquish {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.2);
  }
  50% {
    transform: scaleX(0.8) scaleY(0.8);
  }
  100% {
    transform: scaleX(0) scaleY(0);
  }
}
</style>
