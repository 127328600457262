<template>
  <v-card title="Features enabled for customer">
    <v-card-text>
      <h3 class="mb-3">Add-ons</h3>
      <v-row>
        <v-col v-for="(item, index) in items" :key="index" cols="12" sm="6" md="4" xl="2" class="text-center">
          <v-card :color="item.enabled ? 'purple-9' : 'grey-lighten-2'" :elevation="item.enabled ? 3 : 1">
            <template #title>
              <div class="text-left">
                <v-icon :color="item.enabled ? 'purple-1' : 'error'" size="sm">mdi-checkbox-marked-circle-{{ item.enabled ? 'plus' : 'minus' }}-outline</v-icon>
                {{ item.title }}
              </div>
            </template>

            <v-card-text class="d-flex ga-3 justify-space-between align-center text-left">
              <p>{{ item.description }}</p>
              <v-switch v-model="item.enabled" hide-details class="me-4" color="purple-1" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { onBeforeMount, ref, computed, watch } from 'vue';
import { useYetipayApi } from '@/composables/useYetipayApi';
import { useApplicationStore } from '@/store/application';
import { useMerchantsStore } from '@/store/merchants';

const merchantsStore = useMerchantsStore();
const { api, currentMerchantId } = useYetipayApi();
const applicationStore = useApplicationStore();

interface AddOn {
  title: string;
  key: string;
  description: string;
  enabled: boolean;
}

const items = ref<AddOn[]>();

onBeforeMount(() => {
  const storeAddons = merchantsStore.currentMerchant?.settings?.addOnFeatures;
  items.value = [
    { title: 'Leave at table', key: 'leave-at-table', description: 'Device left at table for guests to pay', enabled: false },
    { title: 'Pay waiter', key: 'pay-waiter', description: "Waiter manages the table's bill across guests", enabled: false },
  ].map<AddOn>((item) => ({ ...item, enabled: !!storeAddons?.includes(item.key) }));
});

const enabledAddOnKeys = computed(() => items.value?.filter((item) => item.enabled).map((item) => item.key));
const loading = ref(false);
const update = async (addOnFeatures?: string[], old?: string[]) => {
  if (!old) return; // Prevent the first call on page load

  loading.value = true;
  try {
    await api.updateMerchantSettings(currentMerchantId, { addOnFeatures });
  } catch (error) {
    console.error(error);
    applicationStore.notifyUser({ message: 'Unable to update the features', type: 'error' });
  } finally {
    loading.value = false;
  }
};
watch(enabledAddOnKeys, update);
</script>
