<template>
  <v-text-field
    ref="inputRef"
    v-model="modelValue"
    class="custom-v-text-field"
    :class="{ 'transparent-caret': transparentCaret }"
    variant="underlined"
    :type="type"
    :autocapitalize="autocapitalize"
    :prefix="prefix"
    :placeholder="placeholder"
    color="white"
    base-color="white"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue';

const inputRef = ref<HTMLInputElement | null>(null);
const transparentCaret = ref(false);

const focus = () => {
  // transparent caret allows field to complete transition before caret is visible
  transparentCaret.value = true;
  inputRef.value?.focus({ preventScroll: true });
  setTimeout(() => {
    transparentCaret.value = false;
  }, 800);
};

const modelValue = defineModel<string>('modelValue', { required: true });
defineModel<string>('type', { default: 'text' });
defineModel<string>('autocapitalize', { default: 'none' });
defineModel<string>('prefix', { default: undefined });
defineModel<string>('placeholder', { default: undefined });

defineExpose<{ focus: () => void }>({
  focus,
});
</script>

<style scoped>
.transparent-caret {
  caret-color: transparent;
}

.custom-v-text-field * {
  color: white !important;
}
</style>
